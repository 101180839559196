import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getBudget_single, editBudget, resetCreation } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import LOADER from "../assets/images/loader.svg";

import Spreadsheet from "react-spreadsheet";



function Budget(props) {

  const { token, resetCreationFN, budgets_single, getBudget_singleFN, match, editBudgetFN, budget_edited } = props;

  const [arra, setarra] = useState([]);
  const [issaving, setissaving] = useState(false);


  const history = useHistory();


  const columnLabels = ["ACCOUNT NO", "DESCRIPTION", "AMOUNT", "UNITS", "RATE", "SUBTOTAL"];


  const handleChange = (newData) => {
    //console.log(JSON.stringify(newData));
    setarra(newData);
  };


  function jsonToCsv(jsonData) {
    // Iterate over the rows in jsonData
    const rows = jsonData.map(row => {
      // Extract the 'value' from each object in the row, or use an empty string if it's missing
      return row.map(cell => (cell.value !== undefined ? cell.value : "")).join(",");
    });

    // Join the rows with newline characters to form the CSV string
    return rows.join("\n");
  }

  // Function to trigger CSV download
  function downloadCsv(csvData, filename = "budget.csv") {
    // Create a blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Remove the link after the download
    document.body.removeChild(link);
  }


  /*
    function transformArray3(inputArray) {
  
      let result = [];
      let i = 1;
      inputArray.forEach(section => {
  
        if (section[0]['value'] === "SUBTOTAL") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F1:F${i - 1})` }]);
  
        }
  
        else if (section[1]['value'] === "SAG_PHW") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "DGA_BTL") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "IATSE") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "IBT") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "FICA") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
  
        else if (section[1]['value'] === "FUI_AND_SUI") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "MED") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "EXTRAS") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[1]['value'] === "WC_AVERAGE") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
  
        else if (section[1]['value'] === "WC_DGA_AND_SAG") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
  
        else if (section[1]['value'] === "PAYROLL_SERVICE") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F172*E${i})/100` }]);
  
        }
  
        else if (section[0]['value'] === "TOTAL") {
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: `=SUM(F1:F${i - 1})` }]);
  
        }
        else {
  
  
          result.push([section[0], section[1], section[2], section[3], section[4], { value: Object.keys(section[0]).length === 1 || (Object.keys(section[0]).length === 0 && Object.keys(section[1]).length === 0) ? "" : `=SUM(C${i}*E${i})` }]);
  
        }
        i++;
  
      });
  
      return result
    };
  
    function transformArray(inputArray) {
      const result = [];
      let sectionNumber = 1100; // Starting number
      let totalCostValue = 0; // Variable to accumulate the total cost
      // let totalCostValue_real = 0; // Variable to accumulate the total cost
      let total_fringes = 0;
      let i = 0;
  
      // Iterate through the input array
      inputArray.forEach(section => {
        const sectionKey = Object.keys(section)[0];
        const sectionItems = section[sectionKey];
  
        if (sectionKey === "TOTAL_FRINGES") {
  
          result.push([{}, {}, {}, {}, {}]);
          result.push([{}, {}, {}, {}, {}]);
          result.push([{}, {}, {}, {}, {}]);
          result.push([{ value: "SUBTOTAL" }, {}, {}, {}, {}, { value: 1 }]);
          result.push([{}, {}, {}, {}, {}]);
          result.push([{ value: "4300" }, { value: "TOTAL_FRINGES" }, {}, {}, {}]);
  
          //  totalCostValue_real = totalCostValue;
          // Handle TOTAL_FRINGES separately
          Object.keys(sectionItems).forEach(fringeKey => {
  
            i++;
            const costValue = parseFloat(sectionItems[fringeKey].COST);
            //const fringeValue = (totalCostValue * costValue / 100).toFixed(2); // Assuming percentage format for fringes
  
            total_fringes = total_fringes + (totalCostValue * costValue / 100);
            result.push([{}, { value: fringeKey }, {}, {}, { value: costValue }, { value: `=SUM(${totalCostValue} * ${costValue / 100})` }]);
  
            //i++;
  
          });
        } else {
          // For other sections, push the section number, section key, and empty objects
          result.push([{ value: sectionNumber.toString() }, { value: sectionKey }, {}, {}, {}]);
  
          // Iterate through the items in the section
          sectionItems.forEach(item => {
            const key = Object.keys(item)[0];
            const itemValues = item[key]; // The array of values for the key
  
            let analysedValue = null;
            let costValue = null;
            let frequencyValue = null;
  
            // Check for ANALYSED, COST, and FREQUENCY in the object
            itemValues.forEach(subItem => {
              if (subItem.ANALYSED !== undefined) {
                analysedValue = subItem.ANALYSED;
              }
              if (subItem.COST !== undefined) {
                costValue = subItem.COST;
              }
              if (subItem.FREQUENCY !== undefined) {
                frequencyValue = subItem.FREQUENCY;
              }
            });
            let tobeadded = isNaN(analysedValue * parseInt(costValue.replace(/[^0-9.-]+/g, ""))) ? 0 : analysedValue * parseInt(costValue.replace(/[^0-9.-]+/g, ""));
            totalCostValue = totalCostValue + tobeadded;
            //console.log(tobeadded);
            // Push the transformed row
            //result.push([{}, { value: key }, { value: analysedValue }, { value: costValue }, { value: frequencyValue || '' }]);
            result.push([{}, { value: key }, { value: analysedValue }, { value: frequencyValue || '' }, { value: parseInt(costValue.replace(/[^0-9.-]+/g, "")) }, { value: costValue ? `=SUM(C${i})` : 0 }]);
            //i++;
          });
  
          // Increase section number by 100
          if (sectionNumber < 1500) {
            sectionNumber += 100;
          } else if (sectionNumber === 1500) {
            sectionNumber = 2000; // Jump to 2000
          } else if (sectionNumber < 2900) {
            sectionNumber += 100;
          } else if (sectionNumber === 2900) {
            sectionNumber = 3100; // Jump to 3100
          } else {
            sectionNumber += 100;
          }
        }
  
        i++;
  
  
      });
  
      // Push the subtotal row
      result.push([{}, {}, {}, {}, {}]);
  
      //   result.push([{ value: "TOTAL" }, {}, {}, {}, {}, { value: (totalCostValue + total_fringes).toFixed(2) }]);
  
      result.push([{ value: "TOTAL" }, {}, {}, {}, {}, { value: "=SUM(F:F)" }]);
  
  
      return result;
    }
    function transformArray2(inputArray) {
      const result = [];
      let sectionNumber = 1100; // Starting number
  
      inputArray.forEach(section => {
        const sectionKey = Object.keys(section)[0];
        const sectionItems = section[sectionKey];
  
        // Push the section number, section key, and four empty objects
        result.push([{ value: sectionNumber.toString() }, { value: sectionKey }, {}, {}, {}]);
  
        // Iterate through the items in the section
        sectionItems.forEach(item => {
          const key = Object.keys(item)[0];
          const itemValues = item[key]; // The array of values for the key
  
          // Variables to hold the values
          let analysedValue = null;
          let costValue = null;
          let frequencyValue = null;
  
          // Check for ANALYSED, COST, and FREQUENCY in the object
          itemValues.forEach(subItem => {
            if (subItem.ANALYSED !== undefined) {
              analysedValue = subItem.ANALYSED;
            }
            if (subItem.COST !== undefined) {
              costValue = subItem.COST;
            }
            if (subItem.FREQUENCY !== undefined) {
              frequencyValue = subItem.FREQUENCY;
            }
          });
  
  
          // Push the transformed row
          result.push([{}, { value: key }, { value: analysedValue }, { value: frequencyValue || '' }, { value: costValue }, { value: costValue ? `=SUM(${analysedValue}*${parseInt(costValue.replace(/[^0-9.-]+/g, ""))})` : "" }]);
        });
  
        // Increase section number by 100
        if (sectionNumber < 1500) {
          sectionNumber += 100;
        } else if (sectionNumber === 1500) {
          sectionNumber = 2000; // Jump to 2000
        } else if (sectionNumber < 2900) {
          sectionNumber += 100;
        } else if (sectionNumber === 2900) {
          sectionNumber = 3100; // Jump to 3100
        } else {
          sectionNumber += 100;
        }
      });
  
      return result;
    }
  
  
  
  
    const data = [
      [{ value: "Vanilla" }, { value: "Chocolate" }],
      [{ value: "Strawberry" }, { value: "Cookies" }],
    ];
  
    */


  useEffect(() => {





    getBudget_singleFN(match.params.id);
    //getScreenplaysFN(page, limit);
    //resetCreationFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (budgets_single && budgets_single.length > 0) {
      //console.log(JSON.stringify(transformArray3(JSON.parse(budgets_single[0]['budget']))));
      //setarra(transformArray3(JSON.parse(budgets_single[0]['budget'])));
      setarra(JSON.parse(budgets_single[0]['budget']));

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgets_single]);



  useEffect(() => {

    if (budget_edited) {

      setissaving(false);
      resetCreationFN();

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget_edited]);




  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >
          <div style={{ height: "15px" }}></div>
          <h1 style={{ padding: "0 20px" }}>{budgets_single && budgets_single.length && budgets_single[0]['title']}</h1>
          <div style={{ height: "15px" }}></div>
          <div style={{ padding: "5px 20px" }}>
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#8F8EFF",
                borderRadius: "3px",
              }}
              onClick={() => {
                //budgetPop.current.open()
                setissaving(true);
                editBudgetFN(match.params.id, arra);
              }}
              disabled={issaving}
            >
              {issaving ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save Budget</b>}
            </button></div>

          <div style={{ padding: "5px 20px" }}>
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#C77CFB",
                borderRadius: "3px",
              }}
              onClick={() => {
                //budgetPop.current.open()

                const csvData = jsonToCsv(arra);
                downloadCsv(csvData);

              }}
            >
              <b>Export Budget as CSV</b>
            </button></div>

          <div style={{ height: "5px" }}></div>

          <Spreadsheet
            data={arra}
            columnLabels={columnLabels}
            className="sheet"
            hideRowIndicators={true}
            onChange={handleChange}
          />


        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
        <div style={{ height: "65px" }}></div>

      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    balance: state.home.balance,
    budgets_single: state.home.budgets_single,
    budget_edited: state.home.budget_edited




  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    editBudgetFN: (a, b) =>
      dispatch(editBudget(a, b)),

    getBudget_singleFN: (a) =>
      dispatch(getBudget_single(a)),


    resetCreationFN: () =>
      dispatch(resetCreation()),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Budget);
