const initState = {
  errore: null,
  recovered: null,
  ricevutomessaggio: null,
  loggedin: false,
  profile: {},
  users: [],
  convs: [],
  cancelled: null,
  erroreimpostazioni: false,
  messages: [],
  token: null,
  screenplays: [],
  budgets: [],
  budgets_all: [],
  budgets_single: [],
  production_data: [],
  images: [],
  breakdowbsheet: null,
  feedback: null,
  ai: {},
  new_screen_id: null,
  archived: false,
  screenplay: {},
  ebooks_admin: [],
  screens_admin: [],
  redirect_url: null,
  logs: [],
  stats: {},
  analytics: {},
  txs: [],
  referrals: [],
  txs_admin: [],
  settings: {},
  settings_admin: {},
  general_settings: {},
  ebook: {},
  balance: {},
  error_budgeting: null,
  error_image: null,
  error_ai: null,
  error_budget_pdf: null,
  pdf_done: null,
  price: 0,
  sub_niches: [],
  titles: [],
  preview: null,
  error_in_creation: null,
  queued: false,
  total: 0,
  page: 1,
  limit: 10,
  loading: false,
  edited: false,
  budget_edited: false,
  production_data_edited: false
};

const homeReducer = (state = initState, action) => {



  switch (action.type) {

    case "RESET_CREATION":
      return {
        ...state,
        errore: null,
        screenplays: [],
        budgets: [],
        budgets_all: [],
        /*budgets_single: [],*/
        images: [],
        breakdowbsheet: null,
        feedback: null,
        ai: {},
        archived: false,
        error_budgeting: null,
        error_image: null,
        error_ai: null,
        new_screen_id: null,
        ebook: {},
        logs: [],
        sub_niches: [],
        screenplay: {},
        titles: [],
        preview: null,
        error_in_creation: null,
        queued: false,
        edited: false,
        budget_edited: false,
        production_data_edited: false,
        redirect_url: null,
        cancelled: null
      };



    case "RESET_CREATION_BUDGET":
      return {
        ...state,
        error_budgeting: null,
        error_budget_pdf: null,
        error_image: null,
        error_ai: null,
        pdf_done: null,
        cancelled: null,
        breakdowbsheet: null,
        feedback: null,

      };


    case "SENT_SUPPORT":
      return {
        ...state,
        errore: null,
        recovered: null,
        ricevutomessaggio: true,
      };

    case "RECOVERED":
      return {
        ...state,
        errore: null,
        recovered: true,
      };


    case "CANCELLED":
      return {
        ...state,
        cancelled: "Yes",
      };

    case "ERROR_RECOVERY":
      return {
        ...state,
        errore: "Error",
      };

    case "ERROR_LOGGING_USERNAME":
      return {
        ...state,
        errore: "There was an error trying to sign you up. Please try again.",
      };


    case "ERROR_IN_STEP":
      return {
        ...state,
        error_in_creation: action.what,
      };


    case "NEW_SCREEN_ID":
      return {
        ...state,
        new_screen_id: action.d,
      };



    case "ERROR_EMAIL_NOT_EXIST":
      return {
        ...state,
        errore: "The e-mail address you entered does not belong to any user.",
      };

    case "ERROR_PWD_LOGIN":
      return {
        ...state,
        errore: "The password you entered does not match. Please try again",
      };

    case "ERROR_PWD":
      return {
        ...state,
        errore: "The passwords you entered do not match. Please try again.",
      };

    case "ERROR_EMAIL":
      return {
        ...state,
        errore: "The e-mail you entered is already associated with a user. ",
      };

    case "ERROR_LOGGING_IN":
      return {
        ...state,
        errore: "There was an error trying to sign you in. Please try again.",
      };



    case "RESET_ERRORS":
      return {
        ...state,
        errore: null,
        error_in_creation: null,
        recovered: null,
        ricevutomessaggio: null,
        loggedin_doi: null,

      };

    case "ERROR_LOGGING":
      return {
        ...state,
        errore: "",
      };

    case "ERROR_UPDATING_PROF":
      return {
        ...state,
        erroreimpostazioni:
          "",
      };

    case "CHANGEDPIC":
      return {
        ...state,
        profile: { ...state.profile, pic: action.d },
      };

    case "USER_PROFILE_DATA":
      return {
        ...state,
        userdata: action.d,
      };

    case "USER_PROFILE_DATA_OTHERS":
      return {
        ...state,
        userdata2: action.d,
      };





    case "REDIRECT_NOW":
      return {
        ...state,
        redirect_url: action.d,
      };


    case "USERS":
      return {
        ...state,
        users: action.d,
      };


    case "ARCHIVED":
      return {
        ...state,
        archived: true,
      };


    case "SCREENPLAY_EDIT":
      return {
        ...state,
        edited: true,
      };

    case "BUDGET_EDIT":
      return {
        ...state,
        budget_edited: true,
      };


    case "PRODUCTION_DATA_EDIT":
      return {
        ...state,
        production_data_edited: true
      };


    case "MESSAGES":
      return {
        ...state,
        messages: action.d,
      };


    case "SCREENPLAYS":
      return {
        ...state,
        screenplays: action.d,
      };

    case "EBOOKS_ADMIN":
      return {
        ...state,
        ebooks_admin: action.d,
      };

    case "SCREENS_ADMIN":
      return {
        ...state,
        screens_admin: action.d,
      };


    case "LOGS":
      return {
        ...state,
        logs: action.d,
      };

    case "BALANCE":
      return {
        ...state,
        balance: action.d,
      };


    case "PRICE":
      return {
        ...state,
        price: action.d,
      };

    case "SETTINGS":
      return {
        ...state,
        settings: action.d,
      };

    case "SETTINGS_ADMIN":
      return {
        ...state,
        settings_admin: action.d,
      };


    case "GENERAL_SETTINGS":
      return {
        ...state,
        general_settings: action.d,
      };



    case "STATS":
      return {
        ...state,
        stats: action.d,
      };


    case "ANALYTICS":
      return {
        ...state,
        analytics: action.d,
      };



    case "TXS":
      return {
        ...state,
        txs: action.d,
      };

    case "REFERRALS":
      return {
        ...state,
        referrals: action.d,
      };

    case "TXS_ADMIN":
      return {
        ...state,
        txs_admin: action.d,
      };


    case "EBOOK":
      return {
        ...state,
        ebook: action.d,
      };

    case "SCREENPLAY":
      return {
        ...state,
        screenplay: action.d,
      };


    case "ERROR_MAKING_BUDGET":
      return {
        ...state,
        error_budgeting: "There was an error generating this budget, please try again later or contact support.",
      };

    case "ERROR_MAKING_IMAGE":
      return {
        ...state,
        error_image: "There was an error generating this scene's sketch, please try again later or contact support.",
      };

    case "ERROR_MAKING_AI":
      return {
        ...state,
        error_ai: "There was an error with the AI Assistant, please try again later or contact support.",
      };


    case "PDF_BUDGET":
      return {
        ...state,
        pdf_done: action.d,
      };

    case "ERROR_PDF_BUDGET":
      return {
        ...state,
        error_budget_pdf: action.d,
      };

    case "AI":
      return {
        ...state,
        ai: action.d,
        error_ai: null
      };

    case "BREAKDOWNSHEET_READY":
      return {
        ...state,
        breakdowbsheet: action.d,
      };

    case "FEEDBACK":
      return {
        ...state,
        feedback: action.d,
      };

    case "IMAGES":
      return {
        ...state,
        images: action.d,
        error_image: null
      };

    case "BUDGETS":
      return {
        ...state,
        budgets: action.d,
        error_budgeting: null
      };


    case "BUDGETS_ALL":
      return {
        ...state,
        budgets_all: action.d,
        error_budgeting: null
      };

    case "BUDGETS_SINGLE":
      return {
        ...state,
        budgets_single: action.d,
        error_budgeting: null
      };


    case "PRODUCTION_DATA":
      return {
        ...state,
        production_data: action.d,
        error_budgeting: null
      };



    case "CONVS":
      return {
        ...state,
        convs: action.d,
      };

    case "NOTIFICHE":
      return {
        ...state,
        notifiche_list: action.notifiche,

      };
    case "UNREADS":
      return {
        ...state,
        unreads: action.msg,
        notifiche: action.notifiche,

      };

    case "UPDATEPROF":
      return {
        ...state,
        //profile: action.d,
        profile: { ...state.profile, ...action.d },
      };


    case "LOG_USER_IN":
      return {
        ...state,
        errore: null,
        token: action.token
      };


    case "LOGGEDIN_CONFIRM":
      return {
        ...state,
        errore: null,
        loggedin: false,
        loggedin_doi: true,
      };


    case "LOGGEDIN":
      return {
        ...state,
        errore: null,
        loggedin: true,
        profile: action.d[0],
      };

    case "LOGGEDOUT":
      return {
        ...initState,
        errore: null,
        loggedin: false,
        profile: {},
        messages: null,
        convs: null,
        token: null


      };

    default:
      return state;
  }
};

export default homeReducer;
