import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Links from "./Links";
import LinksAdmin from "./LinksAdmin";



class Navbar extends Component {
  render() {
    return this.props.location.pathname.startsWith("/admin") ? <LinksAdmin /> : <Links />;
  }
}

export default withRouter(Navbar);
