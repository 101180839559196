import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getScrenPlay, resetCreation, editScreenplay, getBudget, makeBudget, resetCreationBudget, getFullBudget } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { EditorState, convertToRaw, Modifier, RichUtils, convertFromRaw, getDefaultKeyBinding, EditorBlock } from 'draft-js';
//import EdiText from 'react-editext'
import 'react-notifications/lib/notifications.css';
import DataTable from 'react-data-table-component';
import moment from 'moment';

import './ScreenwritingEditor.css';  // Custom CSS file for screenwriting styles

import LOADER from "../assets/images/loader.svg";
//import LOADER2 from "../assets/images/ripples.svg";

import AI from "../assets/images/sparkle.svg";

import { NotificationContainer, NotificationManager } from 'react-notifications';

import ProgressBar from "@ramonak/react-progress-bar";

import Select from 'react-select'

//import { useHistory } from 'react-router-dom';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import debounce from 'lodash/debounce';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import io from 'socket.io-client';


//const URL = "http://localhost:5552";
//const URL2 = "http://localhost:5551";

const URL = "https://ws.filmica.ai";
const URL2 = "https://api.filmica.ai";



const socket = io(URL, { autoConnect: false });



function ScreenAdmin(props) {




  const { token, screenplay, getScrenPlayFN, match, edited, editScreenplayFN, resetCreationFN, getBudgetFN, budgets, balance, error_budgeting, makeBudgetFN, resetCreationBudgetFN, pdf_done } = props;


  const measureRef = useRef(null);
  const [focusedEditorIndex, setFocusedEditorIndex] = useState(0);
  //const [currentblocktype, setcurrentblocktype] = useState("action");

  //const blockTypes = ['scene', 'action', 'character', 'parenthetical', 'dialogue', 'transition'];
  const [BlockcurrentIndex, setBlockcurrentIndex] = useState(0);


  const actions = [
    { value: 'scene', label: 'Scene' },
    { value: 'action', label: 'Action' },
    { value: 'character', label: 'Character' },
    { value: 'parenthetical', label: 'Parenthetical' },
    { value: 'dialogue', label: 'Dialogue' },
    { value: 'transition', label: 'Transition' }
  ];


  const blockTypes = useMemo(() => ['scene', 'action', 'character', 'parenthetical', 'dialogue', 'transition'], []);

  const [sceneprompt, setsceneprompt] = useState("");
  //const [scenesheet, setscenesheet] = useState([]);
  const [pages, setpages] = useState([]);
  const [jumpscenes, setjumptoscenes] = useState([]);
  const [selectedpage, setselectedpage] = useState(null);
  const [selectedscene, setselectedscene] = useState(null);
  const [selectedtype, setselectedtype] = useState(null);

  const [exportid, setexportid] = useState(null);


  const [scene_id, setscene_id] = useState(null);



  //  const [lastAddedPageTime, setLastAddedPageTime] = useState(null);

  const [scenes, setscenes] = useState(0);
  //const [action, setaction] = useState(0);
  const [character, setcharacter] = useState(0);
  //const [dialogue, setdialogue] = useState(0);
  const [runtime, setruntime] = useState(0);
  const [isloading, setisloading] = useState(false);
  const [isgeneratingbudget, setisgeneratingbudget] = useState(false);
  const [isgeneratingbudget2, setisgeneratingbudget2] = useState(false);

  const [title, settitle] = useState("");
  const [pageloaded, setpageloaded] = useState(false);
  const [changed_recently, setchanged_recently] = useState(false);

  //const [budgetviewstep, setbudgetviewstep] = useState(0);
  const [budgetviewstepid, setbudgetviewstepid] = useState(0);
  //const [selectedbudget, setselectedbudget] = useState(null);
  const [totalbudgets, settotalbudgets] = useState({});
  const [totalbudgetamount, settotalbudgetamount] = useState(0);





  const scenePop = useRef();
  const budgetPop = useRef();



  const columns = [

    {
      name: 'Created at',
      selector: row => row.status === 2 ? (<img alt="loader" src={LOADER} />) : (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '60%',
    },



    {
      name: 'Actions',
      width: '40%',
      selector: row => row.status === 2 ? (<p>Generating..</p>) : row.status === 1 ? (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {

        //setselectedbudget(row.id);
        calculateBudgetTotal(row.id);
        setbudgetviewstepid(1);


      }}>View</button>
        {" "}<button style={{
          padding: "5px 10px",
          fontSize: "1rem",
          fontWeight: "600",
          border: 0,
          color: "#fff",
          background: "#C77CFB",
          borderRadius: "3px",
        }} onClick={() => {

          //setselectedbudget(row.id);

          //calculateBudgetTotal(row.id);
          //setbudgetviewstepid(1);
          //setisgeneratingbudget2(true);
          // getFullBudgetFN(row.id);
          window.open(`${URL2}/get_budget_pdf?id=${row.id}`);


        }}
          disabled={isgeneratingbudget2}>

          {isgeneratingbudget2 ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Export Full PDF</b>}

        </button>
      </p >) : (<p>Failed</p>),
      sortable: true,
    }
  ];


  const handleUnload = () => {
    // Reset the editor state
    console.log("unloading..");
    setEditorStates([]);
    // You can also save the editor state to local storage or perform other clean-up tasks here
  };

  useEffect(() => {

    return () => {
      handleUnload();
    };
  }, []);






  //const history = useHistory();

  const [editorStates, setEditorStates] = useState([EditorState.createEmpty()]);
  const editorRefs = useRef([]);

  useEffect(() => {
    editorRefs.current = editorRefs.current.slice(0, editorStates.length);
  }, [editorStates]);


  const handleEditorFocus = (index) => {
    setFocusedEditorIndex(index);
  };




  //const onEditorStateChange = debounce((index, newState) => {

  const onEditorStateChange = (index, newState) => {

    //setscenes(countSceneKeys("scene_"));


    // Check if the current editor content exceeds the height
    //const contentState = newState.getCurrentContent();
    //const contentHeight = getContentHeight(contentState);
    //console.log(isContentEmpty(contentState), editorStates.length > 1, index !== 0);
    //console.log(contentHeight);
    //return true;

    //console.log("allora?", changed_recently);

    if (changed_recently) {
      setchanged_recently(false);
      return true;
    }



    setFocusedEditorIndex(index);
    const newEditorStates = [...editorStates];
    newEditorStates[index] = newState;
    setEditorStates(newEditorStates);

    const selectionState = newState.getSelection();
    const contentState = newState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
    const currentBlockType = currentBlock.getType();
    console.log('Current block type:', currentBlockType);

    setselectedtype({ value: currentBlockType, label: currentBlockType.trim().charAt(0).toUpperCase() + currentBlockType.trim().slice(1).toLowerCase() });

    //const contentHeight = getContentHeight(contentState);


    // setcurrentblocktype(currentBlockType);

    //return false;
    /*
        if (contentHeight > 1056) { // 1056px - container height
          addNewPage();
        }
        else if (isContentEmpty(contentState) && editorStates.length > 1 && index !== 0) {
          //alert(1);
          const currentTime = new Date().getTime();
          //console.log(lastAddedPageTime, (currentTime - lastAddedPageTime) > 1000);
          if (lastAddedPageTime && (currentTime - lastAddedPageTime) > 1000) { // Check if at least 1 second has passed
            removePage(index);
          }
        }
        */
  };
  //}, 0);

  const keyBindingFn = (e) => {
    return getDefaultKeyBinding(e);
  };

  const handleBeforeInput = (chars, editorState) => {
    const contentState = editorState.getCurrentContent();
    const contentHeight = getContentHeight(contentState);
    console.log(contentHeight);

    if (contentHeight > 800) {
      // Prevent adding any new text if height exceeds the threshold
      NotificationManager.warning('Please continue writing on a new page.', 'Page limit exceeded', 3000);

      return 'handled';
    }
    return 'not-handled';
  };




  const handlePastedText = (text, html, editorState, index) => {
    const contentState = editorState.getCurrentContent();
    const contentHeight = getContentHeight(contentState);
    console.log(contentHeight);
    if (contentHeight > 800) {
      NotificationManager.warning('Please continue writing on a new page.', 'Page limit exceeded', 3000);
      return 'handled';
    }

    const selectionState = editorState.getSelection();
    const newContentState = Modifier.replaceText(contentState, selectionState, text);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    onEditorStateChange(index, newEditorState);

    return 'not-handled';
  };


  const getContentHeight = (contentState) => {
    const content = convertToRaw(contentState);
    const text = content.blocks.map(block => block.text || '\n').join('\n');
    if (measureRef.current) {
      measureRef.current.innerText = text;
      //console.log(measureRef.current.offsetHeight);

      return measureRef.current.offsetHeight;
    }
    return 0;
  };



  /*
    const addNewPage = () => {
      const currentTime = new Date().getTime();
      setEditorStates([...editorStates, EditorState.createEmpty()]);
      //setLastAddedPageTime(currentTime); // Set the timestamp when a new page is added
      setTimeout(() => {
        focusEditor(editorRefs.current[editorRefs.current.length - 1]);
      }, 0);
    };
  */
  const addPage = (i, w) => {

    const newEditorState = EditorState.createEmpty();
    let newEditorStates;
    // let newIndex;

    if (w === "up") {
      // Insert the new editor state before index i
      newEditorStates = [
        ...editorStates.slice(0, i),
        newEditorState,
        ...editorStates.slice(i)
      ];
      // newIndex = i; // New editor is at index i
    } else if (w === "down") {
      // Insert the new editor state after index i
      newEditorStates = [
        ...editorStates.slice(0, i + 1),
        newEditorState,
        ...editorStates.slice(i + 1)
      ];
      //newIndex = i + 1; // New editor is at index i + 1
    } else {
      // If w is neither "up" nor "down", do nothing or handle error
      return;
    }

    setEditorStates(newEditorStates);

    setTimeout(() => {
      // focusEditor(editorRefs.current[newIndex]);
    }, 0);

  };

  
    const removePage = (index) => {
      const newEditorStates = editorStates.filter((_, i) => i !== index);
      setEditorStates(newEditorStates);
      //setLastAddedPageTime(null); // Reset the timestamp after removing a page
    };
  


  const handleWrapperClick = (e) => {
    if (e.target.className.includes('editor-container')) {
      //focusEditor(editorRefs.current[editorRefs.current.length - 1]);
    }
  };

  const focusEditor = (editorRef) => {
    if (editorRef && editorRef.focusEditor) {
      editorRef.focusEditor();
    }
  };
  const countSceneKeys_Initial = (json) => {

    let count_scene = 0;
    let count_cha = 0;
    let count_dia = 0;
    let count_act = 0;
    let cs = [];

    for (let o of json) {
      //console.log(o);
      for (let o2 of o['blocks']) {
        //console.log([o2][0]);
        if ([o2][0]['type'] === "scene") {
          count_scene++;
        }

        else if ([o2][0]['type'] === "character") {

          if (!cs.includes([o2][0]['text'])) {
            count_cha++;
            cs.push([o2][0]['text']);
          }
        }

        else if ([o2][0]['type'] === "dialogue") {
          count_dia++;
        }

        else if ([o2][0]['type'] === "action") {
          count_act++;
        }
      }
    }

    //console.log("counting initial.." + count_scene);

    setscenes(count_scene);
    setcharacter(count_cha);
    //scenesAmount.current = count_scene;
    //setdialogue(count_dia);
    //setaction(count_act);

    let r = estimateRuntime(count_scene, count_cha, count_dia, count_act);
    setruntime(r);


  };

  const countSceneKeys = () => {
    let count_scene = 0;
    let count_cha = 0;
    let count_dia = 0;
    let count_act = 0;
    let cs = [];

    let json = editorStates.map(editorState => convertToRaw(editorState.getCurrentContent()));
    //console.log(JSON.stringify(json));

    for (let o of json) {
      //console.log(o);
      for (let o2 of o['blocks']) {
        //console.log([o2][0]);
        if ([o2][0]['type'] === "scene") {
          count_scene++;
        }

        else if ([o2][0]['type'] === "character") {
          if (!cs.includes([o2][0]['text'])) {
            count_cha++;
            cs.push([o2][0]['text']);
          }
        }

        else if ([o2][0]['type'] === "dialogue") {
          count_dia++;
        }

        else if ([o2][0]['type'] === "action") {
          count_act++;
        }
      }



    }

    console.log("counting.." + count_scene);

    setscenes(count_scene);
    setcharacter(count_cha);
    //scenesAmount.current = count_scene;
    //setdialogue(count_dia);
    //setaction(count_act);

    let r = estimateRuntime(count_scene, count_cha, count_dia, count_act);
    setruntime(r);

    // return count;
  };





  const estimateRuntime = (scenes_count, characters_count, dialogues_count, action_count) => {
    // Average durations (in minutes)
    //console.log(scenes_count, dialogues_count, action_count)

    // 196 523 442

    const avgSceneLength = 2; // Average length of a scene in minutes
    const avgDialogueLength = 0.5; // Average length of a dialogue line in minutes
    const avgActionLength = 1; // Average length of an action description in minutes

    // Calculate total times
    const totalScenesTime = scenes_count * avgSceneLength;
    const totalDialoguesTime = dialogues_count * avgDialogueLength;
    const totalActionsTime = action_count * avgActionLength;

    // Sum all times to get the estimated runtime
    const estimatedRuntime = ((totalScenesTime + totalDialoguesTime + totalActionsTime) / 10).toFixed(0);

    return estimatedRuntime;
  }









  useEffect(() => {

    socket.auth = { username: token };
    socket.connect();

    getScrenPlayFN(match.params.id);
    getBudgetFN(match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      //setIsConnected(true);
      //alert(1);
    });

    socket.on('disconnect', () => {
      //setIsConnected(false);
    });

    socket.on('pong', () => {
      //setLastPong(new Date().toISOString());
    });


    socket.on('pm', () => {

      getBudgetFN(match.params.id);
      //alert(2);


    });


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setTimeout(() => {
      setpageloaded(true);

    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // console.log(edited);
    if (error_budgeting) {
      resetCreationBudgetFN();
      setisgeneratingbudget(false);
      //resetCreationFN();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_budgeting]);


  useEffect(() => {

    // console.log(edited);
    if (pdf_done) {
      resetCreationBudgetFN();
      setisgeneratingbudget2(false);
      //resetCreationFN();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf_done]);








  useEffect(() => {

    // console.log(edited);
    if (budgets) {

      setisgeneratingbudget(false);
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgets]);




  useEffect(() => {

    // console.log(edited);
    if (edited) {

      setisloading(false);
      resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);


  useEffect(() => {

    if (screenplay && screenplay.title) {
      settitle(screenplay.title);

    }


    if (screenplay && screenplay.content && screenplay.content !== undefined) {
      //setContent(screenplay.content);
      //setclicked_ebook(ebook.canva_id);
      // console.log(screenplay);
      let o = 0;
      const newEditorStates = [];
      let pag = [];
      let scen = [];



      //const currentTime = new Date().getTime();

      let rawContent = JSON.parse(screenplay.content);
      countSceneKeys_Initial(rawContent)

      for (let c of rawContent) {
        //console.log(c);
        const contentState = convertFromRaw(c);
        newEditorStates[o] = EditorState.createWithContent(contentState)

        setEditorStates(newEditorStates);
        //setLastAddedPageTime(currentTime); // Set the timestamp when a new page is added
        pag.push({ "value": o, "label": "Page " + o });
        //console.log(c);
        for (let block of c['blocks']) {
          if (block.type === "scene") {
            scen.push({ "value": o, "label": block.text });

          }
        }
        o++;

      }

      setpages(pag);
      setjumptoscenes(scen);
    }


    //console.log(ebook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenplay]);



  const calculateBudgetTotal = (budget_id) => {

    setexportid(budget_id);
    //return false;
    let dataF = budgets.filter(item => item.id === budget_id)[0];
    let data = JSON.parse(dataF['budget']);


    //console.log(data);


    const budgetKeys = [
      'cast_budget', 'stunts_budget', 'extras_atmosphere_budget', 'extras_silent_bits_budget',
      'special_effects_budget', 'props_budget', 'vehicles_animals_budget', 'wardrobe_budget',
      'makeup_hair_budget', 'sound_effects_music_budget', 'special_equipment_budget'
    ];

    const totalBudgets = budgetKeys.reduce((totals, key) => {
      totals[key] = 0;
      return totals;
    }, {});

    for (const scene in data) {
      const sceneBudgets = data[scene];
      for (const key in sceneBudgets) {
        if (totalBudgets.hasOwnProperty(key)) {
          totalBudgets[key] += parseFloat(sceneBudgets[key]) || 0;
        }
      }
    }
    //console.log(totalBudgets);
    const totalBudget = Object.values(totalBudgets).reduce((acc, curr) => acc + curr, 0);

    settotalbudgetamount(totalBudget);
    settotalbudgets(totalBudgets);
    //return totalBudgets;
  }

  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const savem = (t) => {
    //serializeContentArray();
    setisloading(true);
    let tot = title;
    if (t) {
      tot = t;
    }

    editScreenplayFN(match.params.id, editorStates.map(editorState => convertToRaw(editorState.getCurrentContent())), tot);

  }

  const handleBlockTypeChange = useCallback((blockType) => {
    console.log(`Block type changed to: ${blockType}`);

    //setcurrentblocktype(blockType);
    setselectedtype({ value: blockType, label: blockType.trim().charAt(0).toUpperCase() + blockType.trim().slice(1).toLowerCase() });

    setEditorStates((prevStates) => {
      if (Array.isArray(prevStates) && prevStates[focusedEditorIndex]) {
        const newEditorStates = [...prevStates];
        newEditorStates[focusedEditorIndex] = RichUtils.toggleBlockType(prevStates[focusedEditorIndex], blockType);
        return newEditorStates;
      }
      return prevStates;
    });


    //focusEditor(editorRefs.current[focusedEditorIndex]);

  }, [focusedEditorIndex]);
  /*const handleBlockTypeChange2 = (blockType) => {

    const newEditorStates = [...editorStates];
    newEditorStates[focusedEditorIndex] = RichUtils.toggleBlockType(editorStates[focusedEditorIndex], blockType);

    setEditorStates(newEditorStates);



    focusEditor(editorRefs.current[focusedEditorIndex]);

  };
*/


  const memoizedHandleBlockTypeChange = useCallback(() => {
    handleBlockTypeChange(blockTypes[BlockcurrentIndex], editorStates, focusedEditorIndex);
  }, [blockTypes, BlockcurrentIndex, handleBlockTypeChange, editorStates, focusedEditorIndex]);



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent the default behavior of the Tab key
        // Call the handle function with the current block type
        handleBlockTypeChange(blockTypes[BlockcurrentIndex]);

        // Update the index to point to the next block type, and wrap around if needed
        setBlockcurrentIndex((prevIndex) => (prevIndex + 1) % blockTypes.length);
        console.log('Tab key pressed');
        // Handle Tab key press here
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedHandleBlockTypeChange, blockTypes.length]);


  const setsceneactions = (scene_id) => {

    setscene_id(scene_id);

    let trovato = ""; // String to hold the 'action' blocks content
    let foundScene = false; // Flag to indicate if the scene is found
    let actionFoundAfterScene = false; // Flag to indicate if actions are found after the scene
    let scenestuff = [];
    let pages = editorStates.map(editorState => convertToRaw(editorState.getCurrentContent()));
    let i = 0;

    for (let page of pages) {
      console.log("checking page " + i);
      for (let block of page['blocks']) {
        if (block.key === scene_id && block.type === "scene") {
          foundScene = true;
          actionFoundAfterScene = true;
        } else if (foundScene && block.type !== "scene") {
          if (block.type === "action") {
            trovato += block.text; // Correct concatenation

          }
          scenestuff.push(block);

        }

        else if (foundScene && block.type === "scene") {
          actionFoundAfterScene = false;
          break;
        }
      }

      if (foundScene && !actionFoundAfterScene) {
        break;
      }

      i++;
    }

    // console.log(scenestuff);
    // setscenesheet(scenestuff);
    setsceneprompt(trovato);
    scenePop.current.open();
    //return trovato;
  }


  const SceneBlock = (props) => {

    const handleClick = (d) => {
      setchanged_recently(true);
      setsceneactions(props.block.key);

      //canvaRef.current.open();
      //alert();
    };



    //return (<EditorBlock {...props} />);

    return (<div>
      <button
        style={{
          marginRight: '10px',
          background: '#0F1028',
          border: '0',
          borderRadius: '5px',
          padding: '0',
          width: '20px',
          height: '20px',
          //display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onMouseDown={handleClick}
      >
        <img alt="ai" src={AI} style={{ height: '10px', width: '10px' }} />
      </button>
      <EditorBlock {...props} style={{ display: "inline" }} />
    </div>)


  };

  const blockRendererFn = (contentBlock) => {




    const type = contentBlock.getType();
    if (type === 'scene') {
      return {
        component: SceneBlock,
        editable: true,

      };
    }
  };


  return token ? (
    <div>
      <div className="container" style={{ marginTop: "60px" }}>


        {pageloaded ? <div className="topmenu">


          <div>
            <div style={{ padding: "5px 10px", display: "inline-block" }}>
              <Select options={actions}
                value={selectedtype}
                onChange={(v) => {
                  setselectedtype(v);
                  //setselectedscene(null);
                  //focusEditor(editorRefs.current[v.value]);
                  handleBlockTypeChange(v.value)
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#C77CFB',
                    primary: '#0F1028',
                  },
                })}

                styles={{
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    background: 'grey',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "3px",
                  }),
                }}

              />
            </div>




            <div style={{ padding: "5px 10px", display: "inline-block" }}>
              <Select options={pages}
                value={selectedpage}
                onChange={(v) => {
                  setselectedpage(v);
                  setselectedscene(null);
                  focusEditor(editorRefs.current[v.value])
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#C77CFB',
                    primary: '#0F1028',
                  },
                })}

                styles={{
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    background: 'grey',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "3px",
                  }),
                }}
                placeholder="Go to Page"

              />
            </div>


            <div style={{ padding: "5px 10px", display: "inline-block" }}>
              <Select options={jumpscenes}
                value={selectedscene}
                onChange={(v) => {
                  setselectedscene(v);
                  setselectedpage(null);
                  focusEditor(editorRefs.current[v.value])
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#C77CFB',
                    primary: '#0F1028',
                  },
                })}

                styles={{
                  menu: (baseStyles, state) => ({
                    ...baseStyles,
                    background: 'grey',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "3px",
                  }),
                }}
                placeholder="Go to Scene"
              />
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>

            <div style={{ padding: "5px 10px", display: "inline-block" }}>
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#fff",
                  background: "#8F8EFF",
                  borderRadius: "3px",
                  width: "100%"
                }}
                onClick={() => {
                  //handleBlockTypeChange('transition')
                  savem();
                }}
                disabled={isloading}
              >
                {isloading ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save Screenplay</b>}

              </button></div>

            <div style={{ padding: "5px 10px", display: "inline-block" }}>
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#fff",
                  background: "#C77CFB",
                  borderRadius: "3px",
                  width: "100%"
                }}
                onClick={() => {
                  budgetPop.current.open()
                }}
              >
                <b>AI Budgeting</b>
              </button></div>

          </div>
        </div> : null}


        <div className="columns" style={{ borderRadius: "3px", alignItems: "flex-start", marginTop: "40px" }}>


          <div
            className="column ml"
            style={{
              color: "black",
              //border: "2px solid #FECB00",
              borderRadius: "10px",
              /*background: "#fff",*/
              /*minHeight: "1000px"*/
              marginTop: "2px",
              padding: 0,
            }}

          >



            {!pageloaded ?
              <div className="fixedd"><img alt="loading.." src={LOADER} style={{ height: "100px" }} /></div> :
              <div className="editor-wrapper" onClick={handleWrapperClick}>


                {/* <div className="leftpanel">
                <p className="leftpanel_title">{screenplay.title}</p>
                <p>Estimated Runtime: <b>{runtime}</b></p>
                <p>Scene Count: <b>{scenes}</b></p>
                <p>Character Count: <b>{character}</b></p>
          </div>*/}


                {editorStates.map((editorState, index) => (
                  <div key={index} className="editor-container" style={{ position: "relative" }}>
                    <div className="pager">{index}</div>
                    <div className="removePage">

                      <div>
                        <button style={{
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          border: 0,
                          color: "#fff",
                          background: "#8F8EFF",
                          borderRadius: "3px",
                        }} onClick={() => removePage(index)}>Remove Page</button>
                      </div>
                    </div>




                    {index === 0 ? <div className="AddPageUp">

                      <div>
                        <button style={{
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          border: 0,
                          color: "#fff",
                          background: "#C77CFB",
                          borderRadius: "3px",
                        }} onClick={() => addPage(index, "up")}>Add Page Up</button>
                      </div>
                    </div> : null}


                    <div className="AddPageDown">

                      <div>
                        <button style={{
                          padding: "5px 10px",
                          fontSize: "0.8rem",
                          fontWeight: "400",
                          border: 0,
                          color: "#fff",
                          background: "#C77CFB",
                          borderRadius: "3px",
                        }} onClick={() => addPage(index, "down")}>Add Page Down</button>
                      </div>
                    </div>



                    <Editor

                      editorState={editorState}
                      onEditorStateChange={(newState) => onEditorStateChange(index, newState)}
                      ref={(ref) => editorRefs.current[index] = ref}
                      handlePastedText={(text, html, editorState) => handlePastedText(text, html, editorState, index)}
                      onFocus={() => handleEditorFocus(index)} // Track focus
                      onContentStateChange={(command) => countSceneKeys()}
                      toolbarHidden
                      editorClassName="editor-content"
                      //keyBindingFn={keyBindingFn}
                      handleBeforeInput={handleBeforeInput}
                      // handleKeyCommand={handleKeyCommand}
                      keyBindingFn={keyBindingFn}
                      blockRendererFn={blockRendererFn}

                      //handlePastedText={handlePastedText}
                      //disabled={index === 0}
                      blockStyleFn={(contentBlock) => {
                        const type = contentBlock.getType();
                        switch (type) {
                          case 'scene':
                            return 'scene-block';
                          case 'action':
                            return 'action-block';
                          case 'character':
                            return 'character-block';
                          case 'parenthetical':
                            return 'parenthetical-block';
                          case 'dialogue':
                            return 'dialogue-block';
                          case 'transition':
                            return 'transition-block';
                          default:
                            return null;
                        }
                      }}
                    />
                  </div>
                ))}
                <div ref={measureRef} className="hidden-measure"></div>
              </div>}

            {/*<div className="editor-wrapper" onClick={handleWrapperClick}>
            {editorStates.map((editorState, index) => (
              <div key={index} className="editor-container">





                <Editor
                  editorState={editorState}
                  onEditorStateChange={(newState) => onEditorStateChange(index, newState)}
                  toolbarHidden={true}
                  editorClassName="editor-content"
                  ref={(ref) => editorRefs.current[index] = ref}
                  handlePastedText={(text, html, editorState) => handlePastedText(text, html, editorState, index)}
                  onFocus={() => handleEditorFocus(index)} // Track focus
                  //handleKeyCommand={(command) => handleKeyCommand(command, editorState, index)}
                  onContentStateChange={(command) => setscenes(countSceneKeys("scene_"))}

                />
              </div>
            ))}
            <div ref={measureRef} className="hidden-measure"></div>

            </div>*/}





          </div>
        </div>




        <div style={{ height: "60px" }}></div>


        <NotificationContainer />

        <Popup
          ref={scenePop}
          contentStyle={{/*margin:"auto 10px"*/ }}
        >

          <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>Scene Prompt:
            </p>
              <div style={{ height: "15px" }}></div>

              <textarea className="input"
                style={{ height: "150px" }}
                placeholder={"Enter a prompt for generating the scene image"}
                value={sceneprompt}
                onChange={(v) => {
                  setsceneprompt(v.target.value);
                }}
              ></textarea>



              <p style={{ padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //scenePop.current.close();
                    alert("Feature pending development")
                  }}
                >
                  <b>Generate Image</b>
                </button></p>



              <p style={{ padding: "5px 0px", marginTop: "10px" }}>
                <button
                  style={budgets.length < 1 ? {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "100%",
                    opacity: 0.25
                  } : {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //scenePop.current.close();
                    window.open(`${URL2}/get_budget_pdf_single?id=${budgets[0]['id']}&key=${scene_id}`)
                    //console.log(scene_id);
                  }}
                  disabled={budgets.length < 1}
                >
                  <b>Download Breakdown Sheet</b>
                </button></p>
              {budgets.length < 1 ? <p style={{ color: "#0F1028", marginTop: "10px" }}>You have not generated an AI Budget yet to generare a breakdown sheet.</p> : null}




            </div>




          </div>
        </Popup>



        <Popup
          ref={budgetPop}
          contentStyle={{/*margin:"auto 10px"*/ }}
          onClose={() => {
            setbudgetviewstepid(0)
          }}
        >
          {budgetviewstepid === 0 ? <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "730px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p>

              <div style={{ height: "15px" }}></div>

              {budgets.length > 0 ? <DataTable
                columns={columns}
                data={budgets}
                //progressPending={loading}
                pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}

                style={{ background: "#E5E6EC" }}

                paginationRowsPerPageOptions={[10, 20, 30]}
                paginationPerPage={10} // Set the default number of rows per page
                paginationTotalRows={budgets.length} // Total number of rows in your data
                paginationDefaultPage={1}



              /> : <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>You haven't generate any budgeting for this script yet.</p>}


              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Close</b>
                </button>

                <button
                  style={balance.ai_credits < 1 || !balance.isPaying ? {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "45%",
                    opacity: 0.15
                  } : {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    //budgetPop.current.close();
                    //resetCreationFN();
                    setisgeneratingbudget(true);
                    makeBudgetFN(match.params.id)
                  }}
                  disabled={balance.ai_credits < 1 || !balance.isPaying || isgeneratingbudget}
                >
                  {isgeneratingbudget ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Generate AI Budget</b>}

                </button>
              </div>


              {error_budgeting ? <p style={{ color: "red", marginTop: "10px" }}>There was a problem generating this budget, please try again.</p> : null}


            </div>




          </div> : <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>Total Budget {formatCurrency(totalbudgetamount)}</p>

              <div style={{ height: "15px" }}></div>
              <div
                style={{
                  background: 'white',
                  padding: '40px 20px',
                  borderRadius: '10px',
                  color: '#0F1028',
                  marginBottom: '20px',
                }}
              >

                {Object.entries(totalbudgets).map(([key, value]) => {
                  const completed = ((value / totalbudgetamount) * 100).toFixed(0);
                  const formattedValue = formatCurrency(value);
                  const label = key.replace(/_/g, ' ').replace('budget', '').trim();

                  //console.log(`perc is ${completed}`);

                  return (

                    <div
                      key={key}

                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',

                      }}
                    >
                      <span style={{ flex: '0 0 auto' }}>{label}</span>
                      <div style={{ flex: '1 1 auto', justifyContent: 'center', padding: '0 20px' }}>
                        <ProgressBar completed={completed} bgColor="#FECB00" style={{ width: '100%' }} />
                      </div>
                      <span style={{ flex: '0 0 auto' }}>{formattedValue}</span>
                    </div>
                  );
                })}
              </div>

              {/*<div style={{ background: "white", padding: "40px 20px", borderRadius: "10px", color: "#0F1028" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <span style={{ flex: "0 0 auto" }}>Cast Members</span>
                  <div style={{ flex: "1 1 auto", justifyContent: "center", padding: "0 20px" }}>
                    <ProgressBar completed={60} bgColor="#FECB00" style={{ width: "100%" }} />
                  </div>
                  <span style={{ flex: "0 0 auto" }}>$1,500.65</span>
                </div>
                </div>*/}

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    setbudgetviewstepid(0);
                    //budgetPop.current.close();
                  }}
                >
                  <b>Back</b>
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    //setbudgetviewstepid(0);
                    //budgetPop.current.close();
                    // setisgeneratingbudget2(true);
                    //getFullBudgetFN(exportid);
                    window.open(`${URL2}/get_budget_pdf?id=${exportid}`);


                  }}
                  disabled={isgeneratingbudget2}
                >
                  {isgeneratingbudget2 ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Export Full PDF</b>}

                </button>
              </div>





            </div>




          </div>}
          {/*<div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>Total Budget $6,500.00</p>

              <div style={{ height: "15px" }}></div>

              <div style={{ background: "white", padding: "40px 20px", borderRadius: "10px", color: "#0F1028" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <span style={{ flex: "0 0 auto" }}>Cast Members</span>
                  <div style={{ flex: "1 1 auto", justifyContent: "center", padding: "0 20px" }}>
                    <ProgressBar completed={60} bgColor="#FECB00" style={{ width: "100%" }} />
                  </div>
                  <span style={{ flex: "0 0 auto" }}>$1,500.65</span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Close</b>
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Export</b>
                </button>
              </div>





            </div>




                </div>*/}
        </Popup>






      </div>

      {pageloaded ? <div className="bottommenu">

        <div>
          <p className="leftpanel_title">{screenplay.title}</p>
          <p style={{ padding: "5px 10px", display: "inline-block" }}>Estimated Runtime: <b>{runtime} minutes</b></p>
          <p style={{ padding: "5px 10px", display: "inline-block" }}>Scene Count: <b>{scenes}</b></p>
          <p style={{ padding: "5px 10px", display: "inline-block" }}>Character Count: <b>{character}</b></p>
        </div>
      </div> : null}
    </div>
  ) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    screenplay: state.home.screenplay,
    edited: state.home.edited,
    budgets: state.home.budgets,
    balance: state.home.balance,
    error_budgeting: state.home.error_budgeting,
    error_budget_pdf: state.home.error_budget_pdf,
    pdf_done: state.home.pdf_done,



  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScrenPlayFN: (a) =>
      dispatch(getScrenPlay(a)),
    getBudgetFN: (a) =>
      dispatch(getBudget(a)),
    resetCreationFN: () =>
      dispatch(resetCreation()),
    makeBudgetFN: (a) =>
      dispatch(makeBudget(a)),
    editScreenplayFN: (a, b, c, d) =>
      dispatch(editScreenplay(a, b, c, d)),

    resetCreationBudgetFN: () =>
      dispatch(resetCreationBudget()),

    getFullBudgetFN: (a) =>
      dispatch(getFullBudget(a)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(ScreenAdmin);
