import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getScreenplays, archiveScreenplay, resetCreation } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import LOADER from "../assets/images/loader.svg";



import io from 'socket.io-client';


//const URL = "http://localhost:5552";
const URL = "https://ws.filmica.ai";

const socket = io(URL, { autoConnect: false });

function MyScreenPlays(props) {

  const { token, screens, getScreenplaysFN, page, limit, loading, /*archiveScreenplayFN, */archived, resetCreationFN } = props;

  const history = useHistory();



  //const [clicked_analytics, setclicked_analytics] = useState([]);


  //const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  // const canvaRef = useRef();
  //const anaRef = useRef();


  useEffect(() => {

    socket.auth = { username: token };
    socket.connect();

    getScreenplaysFN(page, limit);
    resetCreationFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (archived) {
      getScreenplaysFN(page, limit);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  useEffect(() => {
    socket.on('connect', () => {
      //setIsConnected(true);
      //alert(1);
    });

    socket.on('disconnect', () => {
      //setIsConnected(false);
    });

    socket.on('pong', () => {
      //setLastPong(new Date().toISOString());
    });


    socket.on('pm', () => {

      getScreenplaysFN(null, null);
      //alert(2);


    });


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handlePageChange = page => {
    getScreenplaysFN(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getScreenplaysFN(page, newPerPage);
  };




  const columns = [
    {
      name: 'Title',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.title}</p>),
      sortable: true,
      width: '40%',
    },
    {
      name: 'Created at',
      selector: row => row.status === 2 ? (<img alt="loader" src={LOADER} />) : (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '30%',
    },



    {
      name: 'Actions',
      width: '30%',
      selector: row => row.status === 2 ? (<p>Importing..</p>) : (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {
        history.push({
          pathname: '/edit/' + row.id,
        })
      }}>View</button>{" "}<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#8F8EFF",
        borderRadius: "3px",
      }} onClick={() => {
        window.open("https://api.filmica.ai/get_pdf?id=" + row.id + "&user=" + row.user)
      }}>Export as PDF</button>{/*<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#D299FF",
        borderRadius: "3px",
      }} onClick={() => {
        archiveScreenplayFN(row.id);
      }}>Archive</button>*/}
      </p >),
      sortable: true,
    }
  ];


  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >

          {screens === undefined || !screens || screens.length < 1 ? <p>You haven't created any screenplay yet. Click <Link to="/create">here</Link> to create one now.</p> : <DataTable
            title="My Screenplays"
            columns={columns}
            data={screens}
            progressPending={loading}
            pagination
            //paginationServer
            //paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}


            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={screens.length} // Total number of rows in your data
            paginationDefaultPage={1}



          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    screens: state.home.screenplays,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    balance: state.home.balance,
    archived: state.home.archived




  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScreenplaysFN: (a, b) =>
      dispatch(getScreenplays(a, b)),
    archiveScreenplayFN: (id) =>
      dispatch(archiveScreenplay(id)),

    resetCreationFN: () =>
      dispatch(resetCreation()),

  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(MyScreenPlays);
