import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getSettingsAdmin2, saveSettings_admin2 } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';



function AdminSettings(props) {

  const { token, general_settings, getSettingsAdminFN, saveSettingsAdminFN } = props;


  const [credits_monthly, set_credits_monthly] = useState("");
  const [credits_yearly, set_credits_yearly] = useState("");
  const [monthly_award, set_monthly_award] = useState("");
  const [yearly_award, set_yearly_award] = useState("");

  const [price_1, set_price_1] = useState("");
  const [price_2, set_price_2] = useState("");
  const [price_3, set_price_3] = useState("");


  useEffect(() => {

    getSettingsAdminFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (general_settings) {

      set_credits_monthly(general_settings.credits_monthly);
      set_credits_yearly(general_settings.credits_yearly);
      set_monthly_award(general_settings.referral_montly_award);
      set_yearly_award(general_settings.referral_yearly_award);
      set_price_1(general_settings.price_per_credit_1);
      set_price_2(general_settings.price_per_credit_2);
      set_price_3(general_settings.price_per_credit_3);


    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general_settings]);




  return token ? (
    <div className="container" style={{ marginTop: "90px", borderRadius: "3px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          <h1>General Settings</h1>

          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p style={{ fontSize: "0.8em" }}>AI Credits / Monthly Plan:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"AI Credits / Monthly Plan"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_credits_monthly(v.target.value);
                }}
                value={credits_monthly}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>

          <div className="field">
            <p style={{ fontSize: "0.8em" }}>AI Credits / Yearly Plan:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"AI Credits / Yearly Plan"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_credits_yearly(v.target.value);
                }}
                value={credits_yearly}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p style={{ fontSize: "0.8em" }}>Referral Monthly Award:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Referral Monthly Award"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_monthly_award(v.target.value);
                }}
                value={monthly_award}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>


          <div className="field">
            <p style={{ fontSize: "0.8em" }}>Referral Yearly Award:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Referral Yearly Award"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_yearly_award(v.target.value);
                }}
                value={yearly_award}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p style={{ fontSize: "0.8em" }}>Price per Credit | Plan 1:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Price per Credit | Plan 1"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_price_1(v.target.value);
                }}
                value={price_1}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>

          <div className="field">
            <p style={{ fontSize: "0.8em" }}>Price per Credit | Plan 2:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Price per Credit | Plan 2"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_price_2(v.target.value);
                }}
                value={price_2}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>


          <div className="field">
            <p style={{ fontSize: "0.8em" }}>Price per Credit | Plan 3:</p>
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Price per Credit | Plan 3"}
                id="c"
                name="c"
                onChange={(v) => {
                  set_price_3(v.target.value);
                }}
                value={price_3}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>

          {/*<div style={{ height: "5px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Maximum words per outline"}
                id="c"
                name="c"
                onChange={(v) => {
                  setomaxwords(v.target.value);
                }}
                value={maxwords}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-book"></i>
              </span>
            </p>
          </div>


          <div style={{ height: "5px" }}></div>


          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Price per credit/ebook"}
                id="c"
                name="c"
                onChange={(v) => {
                  setprice(v.target.value);
                }}
                value={price}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </p>
          </div>


          <div style={{ height: "10px" }}></div>*/}



          <button
            style={{
              padding: "10px 15px",
              fontSize: "1.1rem",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }}

            onClick={() => {
              saveSettingsAdminFN(credits_monthly, credits_yearly, monthly_award, yearly_award, price_1, price_2, price_3);

            }}
          >
            Save
          </button>
          <div style={{ height: "10px" }}></div>


        </div>
      </div>




      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    general_settings: state.home.general_settings,
    loading: state.home.loading,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsAdminFN: () =>
      dispatch(getSettingsAdmin2()),

    saveSettingsAdminFN: (a, b, c, d, e, f, g) =>
      dispatch(saveSettings_admin2(a, b, c, d, e, f, g)),



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminSettings);
