import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_3446.svg";
import { withRouter } from "react-router-dom";
import { logOut } from "../store/actions/homeActions";
import { connect } from "react-redux";

import { compose } from "redux";

import onClickOutside from 'react-onclickoutside'






class LinksAdmin extends Component {
  state = {
    toggled: false,
  };

  handleClickOutside = () => {
    //alert('onClickOutside() method called');
    let ref = "diocane";
    this.refs[ref].checked = false;
  }


  handleChange = (e) => {
    this.setState({
      toggled: e.target.checked,
    });
  };

  oii = () => {
    if (this.state.toggled) {
      let ref = "diocane";
      this.refs[ref].checked = !this.refs[ref].checked;
    }
  };

  oii2 = () => {
    let ref = "diocane";
    this.refs[ref].checked = false;
  };

  // console.log(this.props.location);

  render() {
    //console.log(this.props.location.pathname);


    return this.props.location.pathname !== "/login" && this.props.token ? (
      <nav
        className={`navbar is-fixed-top ${this.state.scrollTop >= 30 ? "shadow-active" : ""
          }`}
        role="navigation"
        aria-label="main navigation"
        style={{ background: "#0F1028" }}

      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" onClick={this.oii2} to="/admin">
              <img src={logo} className="logonav" alt="Logo" />
            </Link>
            <label
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              htmlFor="nav-toggle-state"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </label>
          </div>
          <input
            ref={"diocane"}
            type="checkbox"
            id="nav-toggle-state"
            onChange={this.handleChange}
          />
          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-end">


              <Link
                className={
                  this.props.location.pathname === "/admin"
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin"

              >
                Dashboard
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/admin/users")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin/users"

              >
                Users
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/admin/data")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin/data"

              >
                Production Cost Data
              </Link>


              <Link
                className={
                  this.props.location.pathname.startsWith("/admin/payments")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin/payments"

              >
                Payments
              </Link>

              <Link
                className={
                  this.props.location.pathname.startsWith("/admin/analytics")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin/analytics"

              >
                Analytics
              </Link>



              <Link
                className={
                  this.props.location.pathname.startsWith("/admin/settings")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={this.oii}
                to="/admin/settings"

              >
                Settings
              </Link>



              <Link
                className={
                  this.props.location.pathname.startsWith("/mesaggi")
                    ? "navbar-item blogle boldme"
                    : "navbar-item blogle"
                }

                onClick={() => {
                  this.props.logOut();
                  window.location.href = "/login";
                }}

                to="/"

              >
                Log Out
              </Link>






            </div>
          </div>
        </div>
      </nav>
    ) : null
  }
}




const mapStateToProps = (state) => {
  return {
    profile: state.home.profile,
    errore: state.home.erroreimpostazioni,
    loggedin: state.home.loggedin,
    userdata: state.home.userdata,
    token: state.home.token,



  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(onClickOutside(LinksAdmin));
