import React, { useState, useRef, useEffect } from "react";

import { login, loginGoogle } from "../store/actions/homeActions";

import logo from "../assets/images/logo_3446.svg";
//import google from "../assets/images/google.svg";

import { connect } from "react-redux";
import { compose } from "redux";

import { Link, useLocation } from "react-router-dom";

import { Redirect } from 'react-router-dom';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';



function Login(props) {

  const location = useLocation();
  const { fromTutorial, niche, selectedSubNiche, selectedTitle, audience, previewstate } = location.state || {};

  const { token, profile, loginFN, errore, loginGoogleFN } = props;
  const [username, setusername] = useState(null);
  const [pwd, setpwd] = useState(null);

  const componentRef = useRef(null);
  const [width, setWidth] = useState(0);


  useEffect(() => {
    if (componentRef.current) {
      const handleResize = () => {
        console.log(componentRef.current.getBoundingClientRect().width);
        setWidth(componentRef.current.getBoundingClientRect().width);
      };

      // Set initial width
      handleResize();

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();

    loginFN(
      username || profile.username,
      pwd || profile.pwd
    );
  };


  const onLoginSuccess = async (response) => {

    loginGoogleFN(response.credential);

  };


  const GoogleSignInButton = ({ onLoginSuccess }) => {
    return (
      <GoogleOAuthProvider style={{ width: "100%" }} clientId="55492711284-7731k1v5vasfjbu6joclm30od1a3b3h3.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={onLoginSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
          width={width}
        />
      </GoogleOAuthProvider>
    );
  };


  return token && !fromTutorial ? <Redirect to="/screenplays" /> : token && fromTutorial ? <Redirect to={{ pathname: "/create", state: { daje: true, niche_2: niche, selectedSubNiche_2: selectedSubNiche, selectedTitle_2: selectedTitle, audience_2: audience, previewstate_2: previewstate } }} /> : (
    <div className="container" style={{ marginTop: "60px", background: "#0F1028" }}>
      <div className="columns is-centered" style={{ margin: 13, background: "#0F1028", borderRadius: "3px" }}>
        <div
          className="column is-half"
          style={{
            color: "white",
            padding: "10px !important",
          }}
        >
          <form onSubmit={handleSubmit} style={{}}>
            <div style={{ height: "10px" }}></div>

            <p className="has-text-centered"><Link to="/"><img src={logo} className="logonav2" alt="Logo" /></Link></p>


            <h1 className="has-text-centered" style={{ color: "white" }}>Sign in to your account:</h1>


            <div style={{ height: "30px" }}></div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="text"
                  placeholder={"Enter your e-mail address"}
                  id="username"
                  name="username"
                  onChange={(v) => {
                    setusername(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>

            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="password"
                  placeholder={"Enter your password"}
                  id="pwd"
                  name="pwd"
                  onChange={(v) => {
                    setpwd(v.target.value);
                  }}
                  defaultValue={profile.pwd}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-key"></i>
                </span>
              </p>
            </div>



            <div className="field">
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#fff",
                  background: "#8F8EFF",
                  borderRadius: "3px",
                  width: "100%"
                }}
                ref={componentRef}
              >
                Sign in
              </button>
              <div style={{ height: "10px" }}></div>

              {/*<button
                style={{
                  padding: "10px 15px",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#000",
                  background: "#eeeeee",
                  borderRadius: "3px",
                  width: "100%"
                }}
              >
                <img style={{ verticalAlign: "bottom" }} alt="Google" src={google} /> {"  "}Sign in with Google
              </button>*/
              }

              <div className="button-container2"><GoogleSignInButton onLoginSuccess={onLoginSuccess}></GoogleSignInButton></div>


            </div>

            {errore ? <p style={{ color: "red" }}>{errore}</p> : null}
          </form>
          <div style={{ height: "40px" }}></div>

          <p className="has-text-centered" style={{ color: "#fff"}}>Don't have an account yet? Click <Link to="/register" style={{ color: "#8F8EFF", fontWeight: 600 }}>here</Link> to create one.</p>
          <div style={{ height: "5px" }}></div>
          <p className="has-text-centered" style={{ color: "#fff"}}>Forgot your password? Click <Link to="/recovery" style={{ color: "#8F8EFF", fontWeight: 600 }}>here</Link> to reset it.</p>




        </div>
      </div>
    </div>);

}

const mapStateToProps = (state) => {
  return {
    profile: state.home.profile,
    errore: state.home.errore,
    loggedin: state.home.loggedin,
    token: state.home.token,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginFN: (username, pwd) =>
      dispatch(login(username, pwd)),
    loginGoogleFN: (username) =>
      dispatch(loginGoogle(username)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
