import React, { useState } from "react";



import { connect } from "react-redux";
import { compose } from "redux";

import { Link } from "react-router-dom";





import Video from "../assets/images/vid2.mp4";
import Video2 from "../assets/images/vid2.webm";


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import axios from '../store/actions/axiosConfig';

function LP(props) {

  const [email, setemail] = useState(null);
  const [error, seterror] = useState(false);
  const [done, setdone] = useState(false);



  const sendemail = (e) => {

    e.preventDefault();

    //const API_URL = "http://localhost:5551";
    const API_URL = "https://api.filmica.ai";


    axios
      .post(API_URL + "/waitlist", { email })

      .then(({ data }) => {
        setdone(true)

      }).catch(({ data }) => {
        seterror(true)

      })
  }

  return (
    <div>
      <div>

        <div className="jumbotron">
          <div className="video-background">
            <div className="video-overlay"></div>
            <video autoPlay loop muted playsInline>
              <source src={Video} />
              <source src={Video2} />

            </video>
          </div>


          <div className="container">
            <div className="columns" style={{
              backgroundImage: ``,
              backgroundSize: "cover",
              height: "55vh",
              padding: 0,
              margin: 0,
              display: "table",
              marginTop: "0px"

            }}>


              <div className="column is-12 has-text-centered mobonly marginmob" style={{ backgroundColor: "", display: "table-cell", verticalAlign: "middle" }}>



                <div style={{ height: "30px" }}></div>


                <div style={{ background: "#E5E6EC", padding: "20px", margin: "0 auto", borderRadius: "10px" }}>

                  <form onSubmit={sendemail}>
                    <div className="field" style={{ margin: "20px auto" }}>
                      <h1 style={{ fontSize: "1.9em", color: "#0F1028" }}><span style={{ color: "#0F1028", fontWeight: 600 }}>Sign Up Now for a Free 3-Month Trial of Filmica AI!</span></h1>

                      <h1 style={{ fontSize: "1.2em", marginTop: "5px" }}><span style={{ color: "#0F1028" }}>Be among the first to experience Filmica, the cutting-edge AI tool designed to streamline your budgeting, image creation, and screenwriting processes. Sign up today and enjoy a free 3-month trial when we launch. Don't miss out on revolutionizing your filmmaking workflow!</span></h1>

                      <div style={{ height: "10px" }}></div>


                      <p className="control is-fullwidth custom-width has-icons-left" style={{ marginTop: "20px" }}>
                        <input
                          required
                          className="input"
                          type="text"
                          placeholder={"Your e-mail address"}
                          id="username"
                          name="username"
                          onChange={(v) => {
                            setemail(v.target.value);
                          }}
                          style={{ color: "#000000", fontSize: "1.2em", }}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </p>
                    </div>


                    <div className="field">
                      <button
                        type="submit"
                        style={{
                          padding: "10px 15px",
                          fontSize: "1.1rem",
                          fontWeight: "600",
                          border: 0,
                          color: "#fff",
                          background: "#8F8EFF",
                          borderRadius: "3px",
                        }}

                      >
                        Join Waiting List
                      </button>

                      <div style={{ height: "20px" }}></div>
                      {done ? <p style={{ color: "#8F8EFF", fontWeight: 600, marginBottom: "10px" }}>Thank you! You have successfully registered to our waiting list, we will be in touch with you shortly</p> : null}
                      {error ? <p style={{ color: "red" }}>Internal error, please try again.</p> : null}

                    </div>
                  </form>
                </div>

              </div>





              <div style={{ height: "30px" }}></div>



            </div>


          </div>
        </div>




        <div style={{ position: "relative", width: " 100%", background: "#fff", color: "#000", backgroundRepeat: "no-repeat", overflow: "hidden", backgroundSize: "cover", backgroundPosition: "center center", zIndex: 1 }}>
          <div className="container">
            <div className="columns is-vcentered is-multiline" style={{
              padding: 0,
              margin: 0,
            }}>


              <div className="column is-12 has-text-centered" style={{ paddingTop: "30px" }}>
                <h1 style={{ fontSize: "2em", color: "#fff" }}><span style={{ color: "#000", fontWeight: 600 }}>F.A.Q.
                </span></h1>
                <div style={{ height: "5px" }}></div>
                <p style={{ fontSize: "1.2em", color: "#000" }}>

                  At Filmica, we understand that transitioning to a new screenwriting platform can come with questions. We've compiled a list of frequently asked questions to help you better understand how our AI-powered tools for analysis, breakdown, budgeting and image generation can revolutionize your creative process.
                </p>
                <div style={{ height: "15px" }}></div>

                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is Filmica?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ color: "#000" }}>
                      Filmica is an AI-powered screenwriting platform designed to simplify the analysis, breakdown and initial budgeting process for filmmakers, screenwriters, and production teams. Our tools help you focus more on creativity and storytelling by handling the technical aspects of production planning.
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does the AI in Filmica assist with budgeting?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ color: "#000" }}>
                      Filmica's AI analyzes your script to provide accurate budgeting estimates. It considers various factors such as location, props, cast, and crew requirements to offer a detailed budget breakdown, helping you manage your production costs effectively.


                    </AccordionItemPanel>
                  </AccordionItem>


                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can Filmica analyze my script for potential improvements?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ color: "#000" }}>

                      Yes, Filmica's AI can provide insights and suggestions to enhance your script. It can identify plot inconsistencies, character development issues, and suggest improvements to ensure your story is compelling and well-structured.
                    </AccordionItemPanel>
                  </AccordionItem>



                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Filmica suitable for both beginners and experienced screenwriters?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ color: "#000" }}>
                      Absolutely! Filmica is designed to be user-friendly for beginners while offering advanced features that experienced screenwriters and production teams will find invaluable. Our platform caters to all levels of expertise in the film industry.
                    </AccordionItemPanel>
                  </AccordionItem>



                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How do I join the Filmica waiting list?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ color: "#000" }}>
                      Simply enter your email address, and you'll be added to the list. You'll receive updates on our launch and be among the first to experience the innovative features Filmica has to offer.
                    </AccordionItemPanel>

                  </AccordionItem>


                </Accordion>




                <div style={{ height: "35px" }}></div>



              </div>







            </div>
          </div>
        </div>




        <div className="container">

          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: "20px" }}>
            <p style={{ color: "black", flex: '1 1 100%', textAlign: 'center' }}>&copy; 2024 filmica</p>
            <p style={{ color: "black", flex: '1 1 100%', textAlign: 'center', marginTop: "20px" }}>
              <Link style={{ color: "black", textDecoration: "underline" }} to="/privacy">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <Link style={{ color: "black", textDecoration: "underline" }} to="/terms">Terms</Link>&nbsp;&nbsp;&nbsp;&nbsp;
              <a style={{ color: "black", textDecoration: "underline" }} href="mailto:hello@filmica.ai">Contact us</a>
            </p>
          </div>


          <div style={{ height: "40px" }}></div>

        </div>

      </div>
    </div >);

}

const mapStateToProps = (state) => {
  return {


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(LP);
