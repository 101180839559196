import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getScreenplays, archiveScreenplay, resetCreation, getBudgets } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import LOADER from "../assets/images/loader.svg";



import io from 'socket.io-client';


//const URL = "http://localhost:5552";
const URL = "https://ws.filmica.ai";

const socket = io(URL, { autoConnect: false });

function Budgets(props) {

  const { token, getScreenplaysFN, loading, resetCreationFN, getBudgetsFN, budgets_all } = props;

  const history = useHistory();



  //const [clicked_analytics, setclicked_analytics] = useState([]);


  //const [totalRows, setTotalRows] = useState(0);


  // const canvaRef = useRef();
  //const anaRef = useRef();


  useEffect(() => {

    socket.auth = { username: token };
    socket.connect();

    getBudgetsFN();
    //getScreenplaysFN(page, limit);
    resetCreationFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    socket.on('connect', () => {
      //setIsConnected(true);
      //alert(1);
    });

    socket.on('disconnect', () => {
      //setIsConnected(false);
    });

    socket.on('pong', () => {
      //setLastPong(new Date().toISOString());
    });


    socket.on('pm', () => {

      //alert(1);
      getBudgetsFN();
      //alert(2);


    });


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);









  const columns = [
    {
      name: 'Screenplay',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.title}</p>),
      sortable: true,
      width: '25%',
    },
    {
      name: 'Created at',
      selector: row => row.status === 2 ? (<img alt="loader" src={LOADER} />) : (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '25%',
    },

    {
      name: 'Engine',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.engine}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Actions',
      width: '40%',
      selector: row => row.status === 2 ? (<p>This will take about 5 minutes, a perfect excuse to enjoy a coffee break! ☕</p>) : row.status === 1 ? (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {

        //setselectedbudget(row.id);
        // calculateBudgetTotal(row.id);
        // setbudgetviewstepid(1);

        history.push({
          pathname: '/budget/' + row.id
        })


      }}>View</button>
        {" "}{/*<button style={{
          padding: "5px 10px",
          fontSize: "1rem",
          fontWeight: "600",
          border: 0,
          color: "#fff",
          background: "#C77CFB",
          borderRadius: "3px",
        }} onClick={() => {


       

          // let dataF = budgets.filter(item => item.id === row.id)[0];

          //setuserid(dataF['user']);
          // window.open(`${URL2}/get_budget_pdf?id=${row.id}&user=${dataF['user']}`);


        }}
        >
          {<b>Export to CSV</b>}

      </button>*/}
      </p >) : (<p>Failed</p>),
      sortable: true,
    }
  ];


  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >

          {budgets_all === undefined || !budgets_all || budgets_all.length < 1 ? <p>You haven't created any budget yet. You can create one on a given Screenplay view.</p> : <DataTable
            title="My Budgets"
            columns={columns}
            data={budgets_all}
            progressPending={loading}
            pagination
            //paginationServer
            //paginationTotalRows={totalRows}
            //onChangeRowsPerPage={handlePerRowsChange}
            //onChangePage={handlePageChange}


            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={budgets_all.length} // Total number of rows in your data
            paginationDefaultPage={1}



          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    loading: state.home.loading,
    balance: state.home.balance,
    budgets_all: state.home.budgets_all




  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScreenplaysFN: (a, b) =>
      dispatch(getScreenplays(a, b)),
    archiveScreenplayFN: (id) =>
      dispatch(archiveScreenplay(id)),

    resetCreationFN: () =>
      dispatch(resetCreation()),
    getBudgetsFN: () =>
      dispatch(getBudgets()),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Budgets);
