import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "../assets/images/logo_3446.svg";
import { logOut } from "../store/actions/homeActions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";


const Links = ({ token, logOut }) => {
  const [toggled, setToggled] = useState(false);
  const input1Ref = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const refDiocane = useRef(null);

  const handleClickOutside = () => {
    if (refDiocane.current) {
      refDiocane.current.checked = false;
    }
  };

  const handleChange = (e) => {
    setToggled(e.target.checked);
  };

  const oii = () => {
    if (toggled && refDiocane.current) {
      refDiocane.current.checked = !refDiocane.current.checked;
    }
  };

  const oii2 = () => {
    if (refDiocane.current) {
      refDiocane.current.checked = false;
    }
  };

  const generateRandomClick = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const randomX = Math.floor(Math.random() * viewportWidth);
    const randomY = Math.floor(Math.random() * viewportHeight);

    const randomClickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: randomX,
      clientY: randomY,
    });

    document.elementFromPoint(randomX, randomY)?.dispatchEvent(randomClickEvent);
  };

  useEffect(() => {
    generateRandomClick();
  });

  return location.pathname !== "/" && location.pathname !== "/login" && token ? (
    <nav
      className={`navbar is-fixed-top`}
      role="navigation"
      aria-label="main navigation"
      style={{ background: "#0F1028" }}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" onClick={oii2} to="/screenplays">
            <img src={logo} className="logonav" alt="Logo" />
          </Link>
          <label
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            htmlFor="nav-toggle-state"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </label>
        </div>
        <input
          ref={refDiocane}
          type="checkbox"
          id="nav-toggle-state"
          onChange={handleChange}
        />
        <div id="navbarBasicExample" className="navbar-menu" ref={input1Ref}>
          <div className="navbar-end">
            <Link
              className={location.pathname.startsWith("/screenplays") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/screenplays"
            >
              My Screenplays
            </Link>
            <Link
              className={location.pathname.startsWith("/budget") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/budgets"
            >
              My Budgets
            </Link>
            <Link
              className={location.pathname.startsWith("/create") || location.pathname.startsWith("/edit") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/create"
            >
              Create New Screenplay
            </Link>
            <Link
              className={location.pathname.startsWith("/import") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/import"
            >
              Import Screenplay
            </Link>
            <Link
              className={location.pathname.startsWith("/billing") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/billing"
            >
              Billing
            </Link>
            <Link
              className={location.pathname.startsWith("/settings") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/settings"
            >
              Settings
            </Link>
            <Link
              className={location.pathname.startsWith("/feedback") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/feedback"
            >
              Feedback
            </Link>
            <Link
              className={location.pathname.startsWith("/mesaggi") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={() => {
                logOut();
                history.push("/login");
              }}
              to="/"
            >
              Log Out
            </Link>
          </div>
        </div>
      </div>
    </nav>
  ) : (
    <nav
      className={`navbar is-fixed-top`}
      role="navigation"
      aria-label="main navigation"
      style={{ background: "#0F1028" }}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" onClick={oii2} to="/">
            <img src={logo} className="logonav" alt="logo" />
          </Link>
          <label
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            htmlFor="nav-toggle-state"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </label>
        </div>
        <input
          ref={refDiocane}
          type="checkbox"
          id="nav-toggle-state"
          onChange={handleChange}
        />
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <Link
              className={location.pathname.startsWith("/mesaggi") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/privacy"
            >
              Privacy Policy
            </Link>
            <Link
              className={location.pathname.startsWith("/mesaggi") ? "navbar-item blogle boldme" : "navbar-item blogle"}
              onClick={oii}
              to="/terms"
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  token: state.home.token,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Links);
