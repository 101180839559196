import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUsersAdmin } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';

import { useHistory } from 'react-router-dom';




function AdminUsers(props) {

  const { token, getUsersAdminFN, page, limit, loading, users } = props;

  const history = useHistory();

  //const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [filterText, setFilterText] = useState('');
  const [filteredData, setFilteredData] = useState(users);

  useEffect(() => {
    const filteredItems = users.filter(item =>
      item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredData(filteredItems);
  }, [filterText, users]);



  useEffect(() => {



    getUsersAdminFN(page, limit);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);


  const handlePageChange = page => {
    getUsersAdminFN(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getUsersAdminFN(page, newPerPage);
  };





  const columns = [

    {
      name: 'User',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.email}</p>),
      sortable: true,
      width: '50%',
    },
    {
      name: 'Signed up on',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '30%',
    },

    {
      name: 'Action',
      width: '20%',
      selector: row => (<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "400",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {
        history.push({
          pathname: '/admin/users/' + row.id,
        })
      }}>Manage User</button>),
    }
  ];


  return token ? (
    <div className="container" style={{ marginTop: "90px",borderRadius: "3px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >

          {users === undefined || !users || users.length < 1 ? <p>No users yet..</p> :
            <DataTable

              title="Manage Users"
              columns={columns}
              data={filteredData}
              progressPending={loading}
              pagination
              paginationServer
              //paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              className="my-data-table"

              paginationRowsPerPageOptions={[10, 20, 30]}
              paginationPerPage={10} // Set the default number of rows per page
              paginationTotalRows={filteredData.length} // Total number of rows in your data
              paginationDefaultPage={1}


              subHeader
              subHeaderComponent={
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                  <input
                    type="text"
                    placeholder="Look Up User"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    style={{ padding: "5px" }}

                  /></div>}


            />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    logs: state.home.logs,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    users: state.home.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersAdminFN: (a, b) =>
      dispatch(getUsersAdmin(a, b)),



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminUsers);
