import axios from 'axios';
import { store } from '../../index'; // Adjust the path based on your file structure

const axiosInstance = axios.create({
    //baseURL: 'http://localhost:5551',
});

axiosInstance.interceptors.request.use(
    function (config) {
        const state = store.getState();
        const token = state.home.token;
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);



axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
            // Dispatch the logout action to clear the state
            store.dispatch({ type: "LOGGEDOUT" });

            // Redirect to the login page
            //history.push('/login');
        }
        return Promise.reject(error);
    }
);


export default axiosInstance;
