import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getScrenPlay, resetCreation, editScreenplay, getBudget, makeBudget, resetCreationBudget, getFullBudget, getImage, makeImage, makeAI, getBalance, makeSheet } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { EditorState, convertToRaw, Modifier, RichUtils, convertFromRaw, getDefaultKeyBinding, EditorBlock, SelectionState } from 'draft-js';
//import EdiText from 'react-editext'
import 'react-notifications/lib/notifications.css';
import DataTable from 'react-data-table-component';
import moment from 'moment';

import './ScreenwritingEditor.css';  // Custom CSS file for screenwriting styles

import LOADER from "../assets/images/loader.svg";
import AII from "../assets/images/aii.mp4";

//import LOADER2 from "../assets/images/ripples.svg";

import AI from "../assets/images/sparkle.svg";


import { NotificationContainer, NotificationManager } from 'react-notifications';

import ProgressBar from "@ramonak/react-progress-bar";

import Select from 'react-select'

import { useHistory } from 'react-router-dom';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import debounce from 'lodash/debounce';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import io from 'socket.io-client';


//const URL = "http://localhost:5552";
//const URL2 = "http://localhost:5551";

//const URL = "https://ws.filmica.ai";
const URL = "https://ws.filmica.ai";
const URL2 = "https://api.filmica.ai";


const socket = io(URL, { autoConnect: false });



function ScreenPlay(props) {




  const { token, screenplay, getScrenPlayFN, match, edited, editScreenplayFN, resetCreationFN, getBudgetFN, budgets, balance, error_budgeting, makeBudgetFN, resetCreationBudgetFN, pdf_done, getImageFN, makeImageFN, error_image, images, makeAIFN, error_ai, ai, getBalanceFN, breakdowbsheet, makeSheetFN } = props;


  const measureRef = useRef(null);
  const editorref = useRef(null);


  const [focusedEditorIndex, setFocusedEditorIndex] = useState(0);
  //const [currentblocktype, setcurrentblocktype] = useState("action");

  //const blockTypes = ['scene', 'action', 'character', 'parenthetical', 'dialogue', 'transition'];
  const [BlockcurrentIndex, setBlockcurrentIndex] = useState(0);


  const actions = [
    { value: 'scene', label: 'Scene' },
    { value: 'action', label: 'Action' },
    { value: 'character', label: 'Character' },
    { value: 'parenthetical', label: 'Parenthetical' },
    { value: 'dialogue', label: 'Dialogue' },
    { value: 'transition', label: 'Transition' }
  ];


  const blockTypes = useMemo(() => ['scene', 'action', 'character', 'parenthetical', 'dialogue', 'transition'], []);

  const [sceneprompt, setsceneprompt] = useState("");
  //const [scenesheet, setscenesheet] = useState([]);
  const [pages, setpages] = useState([]);
  const [jumpscenes, setjumptoscenes] = useState([]);
  const [selectedpage, setselectedpage] = useState(null);
  const [selectedscene, setselectedscene] = useState(null);
  const [selectedtype, setselectedtype] = useState(null);

  const [exportid, setexportid] = useState(null);
  const [userid, setuserid] = useState(null);

  const [what, setwhat] = useState({ value: "us", label: "US" });



  const [scene_id, setscene_id] = useState(null);
  const [makingimage, setmakingimage] = useState(false);
  const [makingai, setmakingai] = useState(false);


  const [productionno, setproductionno] = useState("");
  const [productiontitle, setproductiontitle] = useState("");
  const [breakdownpageno, setbreakdownpageno] = useState("");
  const [sceneno, setsceneno] = useState("");
  const [scenename, setscenename] = useState("");
  const [scriptpageno, setscriptpageno] = useState("");
  const [description, setdescription] = useState("");
  const [pagecount, setpagecount] = useState("");
  const [locationname, setlocationname] = useState("");

  //  const [lastAddedPageTime, setLastAddedPageTime] = useState(null);

  const [scenes, setscenes] = useState(0);
  //const [action, setaction] = useState(0);
  const [character, setcharacter] = useState(0);
  //const [dialogue, setdialogue] = useState(0);
  const [runtime, setruntime] = useState(0);
  const [isloading, setisloading] = useState(false);
  const [isgeneratingbudget, setisgeneratingbudget] = useState(false);
  const [isgeneratingbudget2, setisgeneratingbudget2] = useState(false);

  const [ismakingsheet, setismakingsheet] = useState(false);


  const [title, settitle] = useState("");
  const [pageloaded, setpageloaded] = useState(false);
  const [changed_recently, setchanged_recently] = useState(false);

  //const [budgetviewstep, setbudgetviewstep] = useState(0);
  const [budgetviewstepid, setbudgetviewstepid] = useState(0);
  //const [selectedbudget, setselectedbudget] = useState(null);
  const [totalbudgets, settotalbudgets] = useState({});
  const [totalbudgetamount, settotalbudgetamount] = useState(0);


  const [isblurred, setisblurred] = useState(true);



  const handleBlur = (index) => {
    console.log(`Editor ${index} lost focus`);
    // Delay the blur effect
    setTimeout(() => {
      if (!makingai) {
        setisblurred(true);
      }
    }, 100); // Adjust delay as needed

  };


  const scenePop = useRef();
  const budgetPop = useRef();
  const blockPop = useRef();
  const breakdownPop = useRef();
  const budgetEnginePop = useRef();


  const enforceBlockType = (newState) => {
    const contentState = newState.getCurrentContent();
    const selectionState = newState.getSelection();
    const startKey = selectionState.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);

    // Only enforce block type if it is unstyled
    if (currentBlock.getType() === 'unstyled') {
      const newContentState = Modifier.setBlockType(
        contentState,
        selectionState,
        'scene' // Set this to your desired block type
      );
      return EditorState.push(newState, newContentState, 'change-block-type');
    }

    return newState;
  };






  const ImageDownload = async (imageUrl, scene) => {


    try {
      // Fetch the image as a blob
      const response = await fetch(imageUrl, {
        mode: 'cors',
      });

      // Check if fetch was successful
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `scene-${scene}.jpg`); // Set the filename explicitly
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up after the download
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  };






  const columns2 = [

    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '60%',
    },

    //ImageDownload

    {
      name: 'Actions',
      width: '40%',
      selector: row => (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {

        window.open(row.url)


      }}>View</button>{"  "}<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {

        ImageDownload(row.url, scene_id)


      }}>Download</button></p>),
      sortable: true,
    }
  ];


  const columns = [

    {
      name: 'Created at',
      selector: row => row.status === 2 ? (<img alt="loader" src={LOADER} />) : (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '40%',
    },



    {
      name: 'Actions',
      width: '60%',
      selector: row => row.status === 2 ? (<p>This could take up to 15 minutes, go get a coffee 😊</p>) : row.status === 1 ? (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {

        //setselectedbudget(row.id);
        calculateBudgetTotal(row.id);
        setbudgetviewstepid(1);


      }}>View</button>
        {" "}<button style={{
          padding: "5px 10px",
          fontSize: "1rem",
          fontWeight: "600",
          border: 0,
          color: "#fff",
          background: "#C77CFB",
          borderRadius: "3px",
        }} onClick={() => {

          //setselectedbudget(row.id);

          //calculateBudgetTotal(row.id);
          //setbudgetviewstepid(1);
          //setisgeneratingbudget2(true);
          // getFullBudgetFN(row.id);

          let dataF = budgets.filter(item => item.id === row.id)[0];

          //setuserid(dataF['user']);
          window.open(`${URL2}/get_budget_pdf?id=${row.id}&user=${dataF['user']}`);


        }}
          disabled={isgeneratingbudget2}>

          {isgeneratingbudget2 ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Export Full PDF</b>}

        </button>
      </p >) : (<p>Failed</p>),
      sortable: true,
    }
  ];


  const handleUnload = () => {
    // Reset the editor state
    console.log("unloading..");
    setEditorStates(EditorState.createEmpty());
    // You can also save the editor state to local storage or perform other clean-up tasks here
  };

  useEffect(() => {

    return () => {
      handleUnload();
    };
  }, []);






  const history = useHistory();

  const [editorStates, setEditorStates] = useState(EditorState.createEmpty());
  const lastSelectionRef = useRef(editorStates.getSelection());

  //const editorRefs = useRef();



  const handleEditorFocus = (index) => {
    setisblurred(false);
    setFocusedEditorIndex(index);
  };


  // Create a debounced version of setEditorState
  const debouncedSetEditorState = useCallback(
    debounce((newState) => {
      setEditorStates(newState);
    }, 50), // 500ms debounce time
    []  // Dependencies array; empty means the debounced function is created only once
  );

  //const onEditorStateChange = debounce((index, newState) => {



  const addPlaceholder = (currentBlockType, text, key) => {

    console.log(currentBlockType, text, key);
    /*
        if (currentBlockType === "scene" && text === "") {
    
          const blockNode = document.querySelector(`[data-offset-key="${key}-0-0"]`);
    
          if (blockNode) {
            console.log("metti placeholder");
    
            blockNode.textContent = "SAMPLE TEXT";  // For plain text insertion
            // or
            // blockNode.innerHTML = "SAMPLE TEXT"; // For HTML content insertion
          } else {
            console.error("Element not found");
          }
    
          //return replaceText(selectionState.getStartKey(), "INT./EXT. PLACE - TIME OF DAY");
        }
    
    
    */
  };


  const onEditorStateChange = (newState) => {

    //setscenes(countSceneKeys("scene_"));



    // Check if the current editor content exceeds the height
    //const contentState = newState.getCurrentContent();
    //const contentHeight = getContentHeight(contentState);
    //console.log(isContentEmpty(contentState), editorStates.length > 1, index !== 0);
    //console.log(contentHeight);
    //return true;

    //console.log("allora?", changed_recently);


    //console.log("miaaaao2");

    // const newEditorState = enforceBlockType(newState);
    //    setEditorState(newEditorState);


    //setFocusedEditorIndex(index);
    //const newEditorStates = editorStates;

    // const currentContentState = editorStates.getCurrentContent();
    //const newContentState = newState.getCurrentContent();
    // const currentSelectionState = editorStates.getSelection();
    // const newSelectionState = newState.getSelection();

    const lastChangeType = newState.getLastChangeType();

    const selectionState = newState.getSelection();
    const contentState = newState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(selectionState.getStartKey());


    const currentBlockType = currentBlock.getType();
    const text = currentBlock.getText();


    const currentBlockKey = selectionState.getStartKey();

    // Access all blocks as an OrderedMap
    const blockMap = contentState.getBlockMap();
    const blockKeys = blockMap.keySeq();

    // Find the index of the current block in the list of all keys
    const currentBlockIndex = blockKeys.findIndex(k => k === currentBlockKey);

    // Get the previous block (if current isn't the first one)
    const previousBlockKey = currentBlockIndex > 0 ? blockKeys.get(currentBlockIndex - 1) : null;
    const previousBlock = previousBlockKey ? contentState.getBlockForKey(previousBlockKey) : null;
    const previousBlockText = previousBlock ? previousBlock.getText() : null;


    console.log('Current block type:', currentBlockType);
    setselectedtype({ value: currentBlockType, label: currentBlockType === "unstyled" ? "Scene" : currentBlockType.trim().charAt(0).toUpperCase() + currentBlockType.trim().slice(1).toLowerCase(), key: selectionState.getStartKey(), content: text });

    console.log(`previous block type was ${previousBlock && previousBlock.getType()} and was empty: ${previousBlockText && previousBlockText.trim()}, ao: ${lastChangeType}`);
    /*
        if(previousBlock && previousBlockText.trim() === "" &&  previousBlock.getType() === 'action'){
          //alert(1);
          const newBlockType = 'scene'; // The desired block type to switch to
          const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
          const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
    
          setEditorStates(updatedEditorState);
    
        }
        else if(previousBlock && previousBlockText.trim() === "" &&  previousBlock.getType() === 'scene'){
          //alert(1);
          const newBlockType = 'action'; // The desired block type to switch to
          const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
          const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
    
          setEditorStates(updatedEditorState);
    
        }
    
    */
    addPlaceholder(currentBlockType, text.trim(), currentBlockKey);
    // Only enforce block type if it is unstyled
    if (currentBlockType === "scene" && text.trim() === "") {
      console.log("metti placeholder");
      //return replaceText(selectionState.getStartKey(), "INT./EXT. PLACE - TIME OF DAY");
    }

    else if (currentBlockType === "scene" && text.trim().includes("INT./EXT. PLACE - TIME OF DAY") && text.trim() !== "INT./EXT. PLACE - TIME OF DAY") {
      console.log("leva placeholder");
      //return replaceText(selectionState.getStartKey(), "");
    }

    if (previousBlock && previousBlockText.trim() === "" && previousBlock.getType() !== 'unstyled') {


      if (previousBlock.getType() === 'scene') {

        handleBlockTypeChange('action');
        setBlockcurrentIndex(1);


      }

      else if (previousBlock.getType() === 'action') {

        handleBlockTypeChange('scene');
        setBlockcurrentIndex(0);


      }

      else if (previousBlock.getType() === 'dialogue' || previousBlock.getType() === 'character') {

        handleBlockTypeChange('action');
        setBlockcurrentIndex(1);


      }

      //alert(1);
      //setEditorStates(newState);

    }

    else if (previousBlock && previousBlockText.trim() !== "" && previousBlock.getType() === 'scene') {

      const newContentState = Modifier.setBlockType(
        contentState,
        selectionState,
        'action' // Set this to your desired block type
      );
      setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));
      setBlockcurrentIndex(1);

    }






    /*
        else if (previousBlock && previousBlockText.trim() !== "" && previousBlock.getType() === 'action') {
    
          const newContentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'action' // Set this to your desired block type
          );
          setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));
    
        }
        */


    else {
      if (currentBlock.getType() === 'unstyled') {
        /*const newBlockType = 'scene'; // The desired block type to switch to
        const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
        const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
  
        setEditorStates(updatedEditorState);
       
        debouncedSetEditorState((prevEditorState) => {
          return RichUtils.toggleBlockType(prevEditorState, "scene");
        });
   */
        //alert(1);
        if (previousBlock && previousBlock.getType() === 'action') {

          const newContentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'action' // Set this to your desired block type
          );
          setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));
          setBlockcurrentIndex(1);

        }

        else if (previousBlock && previousBlock.getType() === 'dialogue') {

          const newContentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'character' // Set this to your desired block type
          );
          setBlockcurrentIndex(2);

          setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));

        }

        else if (previousBlock && previousBlock.getType() === 'character') {

          const newContentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'dialogue' // Set this to your desired block type
          );
          setBlockcurrentIndex(3);

          setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));

        }

        else {



          const newContentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'scene' // Set this to your desired block type
          );
          setEditorStates(EditorState.push(newState, newContentState, 'change-block-type'));
          setBlockcurrentIndex(0);
          //return replaceText(selectionState.getStartKey(), "INT./EXT. PLACE - TIME OF DAY");
        }



      }
      /*
          else if (previousBlock && previousBlock.getType() === 'action' && previousBlockText.trim() === "") {
            const newBlockType = 'scene'; // The desired block type to switch to
            const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
            const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
      
            setEditorStates(updatedEditorState);
          }
      
          else if (previousBlock && previousBlock.getType() === 'scene' && previousBlockText.trim() === "") {
      
            const newBlockType = 'action'; // The desired block type to switch to
            const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
            const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
      
            setEditorStates(updatedEditorState);
          }
      
          else if (currentBlock.getType() !== 'unstyled' && previousBlock && previousBlock.getType() === 'scene' && previousBlockText.trim() !== "") {
            const newBlockType = 'action'; // The desired block type to switch to
            const newContentState = Modifier.setBlockType(contentState, selectionState, newBlockType);
            const updatedEditorState = EditorState.push(newState, newContentState, 'change-block-type');
      
            setEditorStates(updatedEditorState);
          }
      */
      else {
        // const newEditorStates = enforceBlockType(newState);
        //  setEditorStates(newEditorStates);
        //debouncedSetEditorState(newState);
        // alert(1);
        setEditorStates(newState);
      }

    }
    //this was working..
    //const newEditorStates = enforceBlockType(newState);
    //setEditorStates(newEditorStates);


    //const newEditorStates = enforceBlockType(newState);
    //setEditorStates(newEditorStates);

    //debouncedSetEditorState(newState);



    //if (textChange) {
    //const newEditorStates = enforceBlockType(newState);
    //setEditorStates(newEditorStates);
    // }

    // setcurrentblocktype(currentBlockType);

    // Check if the content has changed



  };
  //}, 0);

  const keyBindingFn = (e) => {
    return getDefaultKeyBinding(e);
  };





  const replaceText = (key, newText) => {


    const editorState = editorStates;


    //return editorStates.map(editorState => {
    const contentState = editorState.getCurrentContent();
    const selectionState2 = editorState.getSelection();

    const startKey = selectionState2.getStartKey();

    //console.log(startKey, key);

    const block = contentState.getBlockForKey(key);

    // If block with the key is not found, return the editorState unchanged
    if (!block) {
      //console.log(key+ " block not found..");
      return editorState;
    }

    console.log(block);
    const selectionState = SelectionState.createEmpty(key).merge({
      anchorOffset: 0,
      focusOffset: block.getLength(),
    });


    //const selectionState = editorState.getSelection();
    const newContentState = Modifier.replaceText(contentState, selectionState, newText);
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

    onEditorStateChange(newEditorState);
    //  });


  }




  const countSceneKeys_Initial = (json) => {

    let count_scene = 0;
    let count_cha = 0;
    let count_dia = 0;
    let count_act = 0;
    let cs = [];

    //console.log(json['blocks']);

    for (let o2 of json[0]['blocks']) {
      //console.log([o2][0]);
      if ([o2][0]['type'] === "scene") {
        count_scene++;
      }

      else if ([o2][0]['type'] === "character") {

        if (!cs.includes([o2][0]['text'])) {
          count_cha++;
          cs.push([o2][0]['text']);
        }
      }

      else if ([o2][0]['type'] === "dialogue") {
        count_dia++;
      }

      else if ([o2][0]['type'] === "action") {
        count_act++;
      }
    }


    //console.log("counting initial.." + count_scene);

    setscenes(count_scene);
    setcharacter(count_cha);
    //scenesAmount.current = count_scene;
    //setdialogue(count_dia);
    //setaction(count_act);

    let r = estimateRuntime(count_scene, count_cha, count_dia, count_act);
    setruntime(r);


  };

  const countSceneKeys = () => {

    //console.log("counting..");
    let count_scene = 0;
    let count_cha = 0;
    let count_dia = 0;
    let count_act = 0;
    let cs = [];
    let scen = [];

    let json = convertToRaw(editorStates.getCurrentContent());
    //console.log(JSON.stringify(json));

    //console.log(o);
    for (let o2 of json['blocks']) {
      //console.log([o2][0]);
      if ([o2][0]['type'] === "scene") {
        scen.push({ "value": [o2][0]['key'], "label": [o2][0]['text'] });
        count_scene++;
      }

      else if ([o2][0]['type'] === "character") {
        if (!cs.includes([o2][0]['text'])) {
          count_cha++;
          cs.push([o2][0]['text']);
        }
      }

      else if ([o2][0]['type'] === "dialogue") {
        count_dia++;
      }

      else if ([o2][0]['type'] === "action") {
        count_act++;
      }
    }







    //setpages(pag);
    setjumptoscenes(scen);

    console.log("counting.." + count_scene);

    setscenes(count_scene);
    setcharacter(count_cha);
    //scenesAmount.current = count_scene;
    //setdialogue(count_dia);
    //setaction(count_act);

    let r = estimateRuntime(count_scene, count_cha, count_dia, count_act);
    setruntime(r);

    // return count;
  };





  const estimateRuntime = (scenes_count, characters_count, dialogues_count, action_count) => {
    // Average durations (in minutes)
    //console.log(scenes_count, dialogues_count, action_count)

    // 196 523 442

    const avgSceneLength = 2; // Average length of a scene in minutes
    const avgDialogueLength = 0.5; // Average length of a dialogue line in minutes
    const avgActionLength = 1; // Average length of an action description in minutes

    // Calculate total times
    const totalScenesTime = scenes_count * avgSceneLength;
    const totalDialoguesTime = dialogues_count * avgDialogueLength;
    const totalActionsTime = action_count * avgActionLength;

    // Sum all times to get the estimated runtime
    const estimatedRuntime = ((totalScenesTime + totalDialoguesTime + totalActionsTime) / 10).toFixed(0);

    return estimatedRuntime;
  }









  useEffect(() => {

    socket.auth = { username: token };
    socket.connect();

    getScrenPlayFN(match.params.id);
    getBudgetFN(match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      //setIsConnected(true);
      //alert(1);
    });

    socket.on('disconnect', () => {
      //setIsConnected(false);
    });

    socket.on('pong', () => {
      //setLastPong(new Date().toISOString());
    });


    socket.on('pm', () => {

      getBudgetFN(match.params.id);
      //alert(2);


    });


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setTimeout(() => {
      setpageloaded(true);

    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // console.log(edited);
    if (error_budgeting) {
      // resetCreationBudgetFN();
      setisgeneratingbudget(false);
      //resetCreationFN();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_budgeting]);


  useEffect(() => {

    // console.log(edited);
    if (error_image) {
      // resetCreationBudgetFN();
      setmakingimage(false);
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_image]);

  useEffect(() => {

    // console.log(edited);
    if (pdf_done) {
      resetCreationBudgetFN();
      setisgeneratingbudget2(false);
      //resetCreationFN();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf_done]);


  useEffect(() => {

    // console.log(edited);
    if (breakdowbsheet) {
      resetCreationBudgetFN();
      setismakingsheet(false);
      getBalanceFN();
      //resetCreationFN();
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakdowbsheet]);




  useEffect(() => {

    // console.log(edited);
    if (balance.isPaying !== 1) {
      blockPop.current.open();
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);





  useEffect(() => {

    // console.log(edited);
    if (budgets) {
      getBalanceFN();
      setisgeneratingbudget(false);
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgets]);


  useEffect(() => {

    // console.log(edited);
    if (images) {
      getBalanceFN();
      setmakingimage(false);
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {

    // console.log(edited);
    if (ai) {
      getBalanceFN();
      setmakingai(false);
      //alert("replace with " + ai.content + " in " + ai.key);
      replaceText(ai.key, ai.content);
      //resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ai]);


  useEffect(() => {

    // console.log(edited);
    if (edited) {

      setisloading(false);
      resetCreationFN();
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);


  useEffect(() => {

    if (screenplay && screenplay.title) {
      settitle(screenplay.title);

    }


    if (screenplay && screenplay.content && screenplay.content !== undefined) {
      //console.log(screenplay.content);
      //setContent(screenplay.content);
      //setclicked_ebook(ebook.canva_id);
      // console.log(screenplay);
      // let o = 0;
      let newEditorStates;
      //let pag = [];
      let scen = [];



      //const currentTime = new Date().getTime();

      let rawContent = JSON.parse(screenplay.content);
      countSceneKeys_Initial(rawContent)
      const contentState = convertFromRaw(rawContent[0]);
      newEditorStates = EditorState.createWithContent(contentState)

      setEditorStates(newEditorStates);


      for (let block of rawContent[0]['blocks']) {
        if (block.type === "scene") {
          scen.push({ "value": block.key, "label": block.text });

        }
      }



      //setpages(pag);
      setjumptoscenes(scen);
    }


    //console.log(ebook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenplay]);

  const scrollToBlock = (blockKey) => {
    const editorNode = editorref.current;
    if (editorNode) {
      //const blockNode = editorNode.editor.querySelector(`[data-offset-key="${blockKey}-0-0"]`);
      const blockNode = document.querySelector(`[data-offset-key="${blockKey}-0-0"]`);
      if (blockNode) {

        console.log(blockNode, blockKey);

        setTimeout(() => {
          blockNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 500); // Adjust delay as necessary        // Set focus after scrolling
        focusBlock(blockKey);
      }
    }
  };

  const focusBlock = (blockKey) => {
    const selection = SelectionState.createEmpty(blockKey);
    const newSelection = selection.merge({
      focusOffset: 0,
      anchorOffset: 0,
    });
    const newState = EditorState.forceSelection(editorStates, newSelection);
    setEditorStates(newState);
  };

  const calculateBudgetTotal = (budget_id) => {

    setexportid(budget_id);
    //return false;
    let dataF = budgets.filter(item => item.id === budget_id)[0];


    let data = JSON.parse(dataF['budget']);


    //console.log(data);


    const budgetKeys = [
      'cast_budget', 'stunts_budget', 'extras_atmosphere_budget', 'extras_silent_bits_budget',
      'special_effects_budget', 'props_budget', 'vehicles_animals_budget', 'wardrobe_budget',
      'makeup_hair_budget', 'sound_effects_music_budget', 'special_equipment_budget'
    ];

    const totalBudgets = budgetKeys.reduce((totals, key) => {
      totals[key] = 0;
      return totals;
    }, {});

    for (const scene in data) {
      const sceneBudgets = data[scene];
      for (const key in sceneBudgets) {
        if (totalBudgets.hasOwnProperty(key)) {
          totalBudgets[key] += parseFloat(sceneBudgets[key]) || 0;
        }
      }
    }
    //console.log(totalBudgets);
    const totalBudget = Object.values(totalBudgets).reduce((acc, curr) => acc + curr, 0);

    settotalbudgetamount(totalBudget);
    settotalbudgets(totalBudgets);
    //return totalBudgets;
  }

  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const savem = (t) => {
    //serializeContentArray();
    setisloading(true);
    let tot = title;
    if (t) {
      tot = t;
    }

    editScreenplayFN(match.params.id, convertToRaw(editorStates.getCurrentContent()), tot);

  }

  const handleBlockTypeChange = useCallback((blockType) => {
    console.log(`Block type changed to: ${blockType}`);

    //setcurrentblocktype(blockType);
    setselectedtype({ value: blockType, label: blockType.trim().charAt(0).toUpperCase() + blockType.trim().slice(1).toLowerCase() });

    setEditorStates((prevEditorState) => {
      return RichUtils.toggleBlockType(prevEditorState, blockType);
    });


    //focusEditor(editorRefs.current[focusedEditorIndex]);

  }, [focusedEditorIndex]);
  /*const handleBlockTypeChange2 = (blockType) => {
 
    const newEditorStates = [...editorStates];
        newEditorStates[focusedEditorIndex] = RichUtils.toggleBlockType(editorStates[focusedEditorIndex], blockType);
 
        setEditorStates(newEditorStates);
 
 
 
        focusEditor(editorRefs.current[focusedEditorIndex]);
 
  };
        */


  const memoizedHandleBlockTypeChange = useCallback(() => {
    handleBlockTypeChange(blockTypes[BlockcurrentIndex], editorStates, focusedEditorIndex);
  }, [blockTypes, BlockcurrentIndex, handleBlockTypeChange, editorStates, focusedEditorIndex]);



  useEffect(() => {




    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent the default behavior of the Tab key

        //alert(BlockcurrentIndex);

        if (BlockcurrentIndex === 1) {

          handleBlockTypeChange('character');
          setBlockcurrentIndex(2);


        }

        else {
          handleBlockTypeChange(blockTypes[BlockcurrentIndex]);
          setBlockcurrentIndex((prevIndex) => (prevIndex + 1) % blockTypes.length);


        }
        // Call the handle function with the current block type

        // Update the index to point to the next block type, and wrap around if needed
        console.log('Tab key pressed');
        // Handle Tab key press here



      }



    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedHandleBlockTypeChange, blockTypes.length]);


  const getDescriptionAndSceneNo = (scene_id) => {

    // setscene_id(scene_id);
    //getImageFN(scene_id);

    let trovato = ""; // String to hold the 'action' blocks content
    let foundScene = false; // Flag to indicate if the scene is found
    let actionFoundAfterScene = false; // Flag to indicate if actions are found after the scene
    let scenestuff = [];
    let pages = convertToRaw(editorStates.getCurrentContent());
    let i = 0;
    let scenei = 0;

    //for (let page of pages) {
    console.log("checking page " + i);
    for (let block of pages['blocks']) {
      if (block.type === "scene") {

        scenei++;
      }

      if (block.key === scene_id && block.type === "scene") {
        foundScene = true;
        actionFoundAfterScene = true;
      } else if (foundScene && block.type !== "scene") {
        if (block.type === "action") {
          trovato += block.text; // Correct concatenation

        }
        scenestuff.push(block);

      }

      else if (foundScene && block.type === "scene") {
        actionFoundAfterScene = false;
        break;
      }
    }

    return { scenei, desc: trovato.substring(0, 100) }

    // }

    // console.log(scenestuff);
    // setscenesheet(scenestuff);

    //setsceneprompt(trovato);
    //scenePop.current.open();
    //return trovato;
  }


  const setsceneactions = (scene_id) => {

    setscene_id(scene_id);
    getImageFN(scene_id);

    let trovato = ""; // String to hold the 'action' blocks content
    let foundScene = false; // Flag to indicate if the scene is found
    let actionFoundAfterScene = false; // Flag to indicate if actions are found after the scene
    let scenestuff = [];
    let pages = convertToRaw(editorStates.getCurrentContent());
    let i = 0;

    //for (let page of pages) {
    console.log("checking page " + i);
    for (let block of pages['blocks']) {
      if (block.key === scene_id && block.type === "scene") {
        foundScene = true;
        actionFoundAfterScene = true;
      } else if (foundScene && block.type !== "scene") {
        if (block.type === "action") {
          trovato += block.text; // Correct concatenation

        }
        scenestuff.push(block);

      }

      else if (foundScene && block.type === "scene") {
        actionFoundAfterScene = false;
        break;
      }
    }


    // }

    // console.log(scenestuff);
    // setscenesheet(scenestuff);

    setsceneprompt(trovato);
    scenePop.current.open();
    //return trovato;
  }

  const handleKeyCommand = (command, editorState) => {
    if (command === 'split-block') { // Intercept Enter key
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const blockKey = selectionState.getStartKey();
      const block = contentState.getBlockForKey(blockKey);
      alert(1)
      if (block.getType() === 'scene' && block.getText().trim() === '') {
        return 'handled'; // Prevent default Enter behavior

      }
      // Add more conditions if necessary
    }
    return 'not-handled';
  };

  const blockRendererFn = (block) => {


    if (block.getType() === 'scene') {
      return {
        props: {
          'data-block-type': 'scene'
        }
      };
    }


  };


  return token ? (
    <div>
      <div className="container" style={{ marginTop: "60px" }}>


        {pageloaded ?

          <div className="topmenu2">
            <div>
              <div style={{ padding: "5px 10px" }}>
                <Select options={actions}
                  value={selectedtype}
                  onChange={(v) => {
                    setselectedtype(v);
                    //setselectedscene(null);
                    //focusEditor(editorRefs.current[v.value]);
                    handleBlockTypeChange(v.value)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}

                />
              </div>







              <div style={{ padding: "5px 10px" }}>
                <Select options={jumpscenes}
                  value={selectedscene}
                  onChange={(v) => {
                    setselectedscene(v);
                    scrollToBlock(v.value);
                    //setselectedpage(null);
                    //focusEditor(editorRefs.current[v.value])
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}
                  placeholder="Go to Scene"
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>

              <div style={{ padding: "5px 10px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //handleBlockTypeChange('transition')
                    savem();
                  }}
                  disabled={isloading}
                >
                  {isloading ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save Screenplay</b>}

                </button></div>

              <div style={{ padding: "5px 10px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //budgetPop.current.open()

                    return budgetEnginePop.current.open();

                    resetCreationBudgetFN();
                    makeBudgetFN(match.params.id)

                    setTimeout(() => {
                      history.push({
                        pathname: '/budgets'
                      })
                    }, 800);



                  }}
                  disabled={balance && balance.ai_credits < 1 || !balance.isPaying || isgeneratingbudget}

                >
                  <b>Generate AI Budget</b>
                </button></div>
              {!makingai && <p style={{ color: "#fff", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p>}

            </div>
          </div> : null}





        {
          (((!isblurred || makingai)) && pageloaded && selectedtype && (selectedtype.label === "Action" || selectedtype.label === "Dialogue")) ?

            //AII
            <div className="topmenu3">
              <div>
                <div style={{ padding: "5px 10px" }}>
                  {makingai ? <span style={{ color: "#0F1028", fontWeight: 600 }}>Rewriting..</span> : <span style={{ color: "#0F1028", fontWeight: 600 }}>AI Assistant Available</span>}
                  {
                    makingai ? <video src={AII} muted autoPlay loop>
                      Your browser does not support the video tag.
                    </video> : null}

                  {!makingai ? <div style={{ padding: "5px 10px", marginTop: "10px" }}>
                    <button
                      style={balance && balance.ai_credits < 1 ? {
                        padding: "10px 15px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        border: 0,
                        color: "#fff",
                        background: "#0F1028",
                        borderRadius: "3px",
                        width: "100%",
                        opacity: 0.5
                      } : {
                        padding: "10px 15px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        border: 0,
                        color: "#fff",
                        background: "#0F1028",
                        borderRadius: "3px",
                        width: "100%"
                      }}
                      onClick={() => {
                        resetCreationBudgetFN();
                        setmakingai(true);
                        makeAIFN(selectedtype.content, selectedtype.label, selectedtype.key)
                      }}
                      disabled={balance && balance.ai_credits < 1}
                    >
                      <b>Rewrite {selectedtype && selectedtype.label}</b>
                    </button></div> : null}

                  {!makingai && <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p>}
                  {!makingai && error_ai ? <p style={{ color: "red", marginTop: "10px" }}>There was a problem with the AI Assistant, please try again.</p> : null}

                </div>








              </div>






            </div> : null}



        {
          (((!isblurred)) && pageloaded && selectedtype && (selectedtype.label === "Scene")) ?

            //AII
            <div className="topmenu4">
              <div>
                <div>



                  <div style={{ padding: "5px 10px", marginTop: "10px" }}>
                    <button
                      style={balance && balance.ai_credits < 1 ? {
                        padding: "10px 15px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        border: 0,
                        color: "#fff",
                        background: "#0F1028",
                        borderRadius: "3px",
                        width: "100%",
                        opacity: 0.5
                      } : {
                        padding: "10px 15px",
                        fontSize: "1rem",
                        fontWeight: "600",
                        border: 0,
                        color: "#fff",
                        background: "#0F1028",
                        borderRadius: "3px",
                        width: "100%"
                      }}
                      onClick={() => {
                        setsceneactions(selectedtype.key);

                      }}
                      disabled={balance && balance.ai_credits < 1}
                    >
                      <b>Generate Scene Image</b>
                    </button></div>
                </div>

                <div style={{ padding: "5px 0px", marginTop: "10px" }}>
                  <button
                    style={budgets.length < 1 ? {
                      padding: "10px 15px",
                      fontSize: "1rem",
                      fontWeight: "600",
                      border: 0,
                      color: "#fff",
                      background: "#0F1028",
                      borderRadius: "3px",
                      width: "100%",
                      opacity: 0.25
                    } : {
                      padding: "10px 15px",
                      fontSize: "1rem",
                      fontWeight: "600",
                      border: 0,
                      color: "#fff",
                      background: "#0F1028",
                      borderRadius: "3px",
                      width: "100%"
                    }}
                    onClick={() => {
                      //breakdownPop.current.open();
                      let s = getDescriptionAndSceneNo(selectedtype.key);
                      console.log(s);
                      const locationname = selectedtype.content.split('.').slice(1).join('.').trim();
                      setlocationname(locationname);
                      setscenename(selectedtype.content);
                      setsceneno(s['scenei'] - 1);
                      setdescription(s['desc']);
                      //console.log(result);
                      breakdownPop.current.open();
                      //window.open(`${URL2}/get_budget_pdf_single?id=${budgets[0]['id']}&key=${selectedtype.key}&user=${budgets[0]['user']}`)
                      //console.log(scene_id);
                    }}
                    disabled={budgets.length < 1}
                  >
                    <b>Generate Breakdown Sheet</b>
                  </button></div>
                {budgets.length < 1 ? <p style={{ color: "#0F1028", marginTop: "10px", fontSize: "0.8em" }}>You have not yet generated an AI budget to create a breakdown sheet.</p> : null}







              </div>






            </div> : null}


        {/*pageloaded ?

          <div className="topmenu">
            <div>
              <div style={{ padding: "5px 10px", display: "inline-block" }}>
                <Select options={actions}
                  value={selectedtype}
                  onChange={(v) => {
                    setselectedtype(v);
                    //setselectedscene(null);
                    //focusEditor(editorRefs.current[v.value]);
                    handleBlockTypeChange(v.value)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}

                />
              </div>




              <div style={{ padding: "5px 10px", display: "inline-block" }}>
                <Select options={pages.slice(1)}
                  value={selectedpage}
                  onChange={(v) => {
                    setselectedpage(v);
                    setselectedscene(null);
                    focusEditor(editorRefs.current[v.value])
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}
                  placeholder="Go to Page"

                />
              </div>


              <div style={{ padding: "5px 10px", display: "inline-block" }}>
                <Select options={jumpscenes}
                  value={selectedscene}
                  onChange={(v) => {
                    setselectedscene(v);
                    setselectedpage(null);
                    focusEditor(editorRefs.current[v.value])
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}
                  placeholder="Go to Scene"
                />
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>

              <div style={{ padding: "5px 10px", display: "inline-block" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //handleBlockTypeChange('transition')
                    savem();
                  }}
                  disabled={isloading}
                >
                  {isloading ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save Screenplay</b>}

                </button></div>

              <div style={{ padding: "5px 10px", display: "inline-block" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    budgetPop.current.open()
                  }}
                >
                  <b>AI Budgeting</b>
                </button></div>

            </div>
                </div> : null*/}


        <div className="columns" style={{ borderRadius: "3px", alignItems: "flex-start", marginTop: "40px" }}>


          <div
            className="column ml"
            style={{
              color: "black",
              //border: "2px solid #FECB00",
              borderRadius: "10px",
              /*background: "#fff",*/
              /*minHeight: "1000px"*/
              marginTop: "2px",
              padding: 0,
            }}

          >



            {!pageloaded ?
              <div className="fixedd"><img alt="loading.." src={LOADER} style={{ height: "100px" }} /></div> :
              <div className="editor-wrapper">



                <div className="editor-container" style={{ position: "relative" }}>


                  <Editor

                    editorState={editorStates}
                    onEditorStateChange={(newState) => onEditorStateChange(newState)}
                    //ref={(ref) => editorRefs.current[0] = ref}
                    ref={editorref}
                    //handlePastedText={(text, html, editorState) => handlePastedText(text, html, editorState, index)}
                    onFocus={() => handleEditorFocus(0)} // Track focus
                    onContentStateChange={(command) => countSceneKeys()}
                    toolbarHidden
                    editorClassName="editor-content"
                    //keyBindingFn={keyBindingFn}
                    //handleBeforeInput={handleBeforeInput}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={keyBindingFn}
                    //blockRendererFn={blockRendererFn}
                    onBlur={() => handleBlur(0)}
                    placeholder={selectedtype && selectedtype.value === 'scene' ? "Aoooooo" : null}
                    //handlePastedText={handlePastedText}
                    //disabled={index === 0}
                    blockStyleFn={(contentBlock) => {
                      const type = contentBlock.getType();
                      switch (type) {
                        case 'scene':
                          return 'scene-block';
                        case 'action':
                          return 'action-block';
                        case 'character':
                          return 'character-block';
                        case 'parenthetical':
                          return 'parenthetical-block';
                        case 'dialogue':
                          return 'dialogue-block';
                        case 'transition':
                          return 'transition-block';
                        default:
                          return null;
                      }
                    }}
                  />
                </div>

                <div ref={measureRef} className="hidden-measure"></div>
              </div>}







          </div>
        </div>




        <div style={{ height: "60px" }}></div>


        <NotificationContainer />

        <Popup
          ref={scenePop}
          contentStyle={{/*margin:"auto 10px"*/ }}
          closeOnDocumentClick={makingimage ? false : true} // prevent closing on clicking outside
          closeOnEscape={makingimage ? false : true} // prevent closing with the escape key
        >

          <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>

              {images && images.length > 0 && !makingimage ? <p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>Images:</p> : null}
              {images && images.length > 0 && !makingimage ? <div style={{ height: "15px" }}></div> : null}
              {images && images.length > 0 && !makingimage ? <DataTable
                columns={columns2}
                data={images}
                //progressPending={loading}
                pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}

                style={{ background: "#E5E6EC" }}

                paginationRowsPerPageOptions={[5, 10, 15]}
                paginationPerPage={5} // Set the default number of rows per page
                paginationTotalRows={images.length} // Total number of rows in your data
                paginationDefaultPage={1}



              /> : null}
              {images && images.length > 0 && !makingimage ? <div style={{ height: "15px" }}></div> : null}

              {makingimage ? <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.1em" }}>Generating Image..
              </p> :
                <p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>Scene Prompt:
                </p>
              } <div style={{ height: "15px" }}></div>

              {makingimage ? <iframe title="loader" style={{ margin: "0 auto", display: "block" }} src="https://lottie.host/embed/ae8c30e3-ff4d-40c3-9a66-1348d30bc659/3gnfSFCRE0.json"></iframe> : <textarea className="input"
                style={{ height: "150px" }}
                placeholder={"Enter a prompt for generating the scene image"}
                value={sceneprompt}
                onChange={(v) => {
                  setsceneprompt(v.target.value);
                }}
              ></textarea>}



              {!makingimage ? <p style={{ padding: "5px 0px", marginTop: "20px" }}>
                <button
                  disabled={balance && balance.ai_credits < 1}
                  style={balance && balance.ai_credits < 1 ? {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%",
                    opacity: 0.4
                  } : {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //scenePop.current.close();
                    //alert("Feature pending development")
                    resetCreationBudgetFN();
                    setmakingimage(true);
                    makeImageFN(sceneprompt, match.params.id, scene_id);
                  }}
                >
                  <b>Generate Image</b>
                </button></p> : null}
              {!makingimage ? <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p> : null}

              {error_image ? <p style={{ color: "red", marginTop: "10px" }}>There was a problem generating this scene's image, please try again.</p> : null}








            </div>




          </div>
        </Popup>



        <Popup
          ref={budgetPop}
          contentStyle={{/*margin:"auto 10px"*/ }}
          onClose={() => {
            setbudgetviewstepid(0)
          }}
        >
          {budgetviewstepid === 0 ? <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "730px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p>

              <div style={{ height: "15px" }}></div>

              {budgets.length > 0 ? <DataTable
                columns={columns}
                data={budgets}
                //progressPending={loading}
                pagination
                //paginationServer
                //paginationTotalRows={totalRows}
                //onChangeRowsPerPage={handlePerRowsChange}
                //onChangePage={handlePageChange}

                style={{ background: "#E5E6EC" }}

                paginationRowsPerPageOptions={[10, 20, 30]}
                paginationPerPage={10} // Set the default number of rows per page
                paginationTotalRows={budgets.length} // Total number of rows in your data
                paginationDefaultPage={1}



              /> : <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>You haven't generate any budgeting for this script yet.</p>}


              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Close</b>
                </button>

                <button
                  style={balance.ai_credits < 1 || !balance.isPaying ? {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "45%",
                    opacity: 0.15
                  } : {
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#C77CFB",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    //budgetPop.current.close();
                    //resetCreationFN();
                    resetCreationBudgetFN();
                    setisgeneratingbudget(true);
                    makeBudgetFN(match.params.id)
                  }}
                  disabled={balance.ai_credits < 1 || !balance.isPaying || isgeneratingbudget}
                >
                  {isgeneratingbudget ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Generate AI Budget</b>}

                </button>
              </div>


              {error_budgeting ? <p style={{ color: "red", marginTop: "10px" }}>There was a problem generating this budget, please try again.</p> : null}


            </div>




          </div> : <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>Total Budget {formatCurrency(totalbudgetamount)}</p>

              <div style={{ height: "15px" }}></div>
              <div
                style={{
                  background: 'white',
                  padding: '40px 20px',
                  borderRadius: '10px',
                  color: '#0F1028',
                  marginBottom: '20px',
                }}
              >

                {Object.entries(totalbudgets).map(([key, value]) => {
                  const completed = ((value / totalbudgetamount) * 100).toFixed(0);
                  const formattedValue = formatCurrency(value);
                  const label = key.replace(/_/g, ' ').replace('budget', '').trim();

                  //console.log(`perc is ${completed}`);

                  return (

                    <div
                      key={key}

                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',

                      }}
                    >
                      <span style={{ flex: '0 0 auto' }}>{label}</span>
                      <div style={{ flex: '1 1 auto', justifyContent: 'center', padding: '0 20px' }}>
                        <ProgressBar completed={completed} bgColor="#FECB00" style={{ width: '100%' }} />
                      </div>
                      <span style={{ flex: '0 0 auto' }}>{formattedValue}</span>
                    </div>
                  );
                })}
              </div>

              {/*<div style={{ background: "white", padding: "40px 20px", borderRadius: "10px", color: "#0F1028" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <span style={{ flex: "0 0 auto" }}>Cast Members</span>
                  <div style={{ flex: "1 1 auto", justifyContent: "center", padding: "0 20px" }}>
                    <ProgressBar completed={60} bgColor="#FECB00" style={{ width: "100%" }} />
                  </div>
                  <span style={{ flex: "0 0 auto" }}>$1,500.65</span>
                </div>
                </div>*/}

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    setbudgetviewstepid(0);
                    //budgetPop.current.close();
                  }}
                >
                  <b>Back</b>
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    //setbudgetviewstepid(0);
                    //budgetPop.current.close();
                    // setisgeneratingbudget2(true);
                    //getFullBudgetFN(exportid);
                    window.open(`${URL2}/get_budget_pdf?id=${exportid}`);


                  }}
                  disabled={isgeneratingbudget2}
                >
                  {isgeneratingbudget2 ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Export Full PDF</b>}

                </button>
              </div>





            </div>




          </div>}
          {/*<div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>
              <p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.5em" }}>{screenplay.title}</p>
              <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1.2em" }}>Total Budget $6,500.00</p>

              <div style={{ height: "15px" }}></div>

              <div style={{ background: "white", padding: "40px 20px", borderRadius: "10px", color: "#0F1028" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <span style={{ flex: "0 0 auto" }}>Cast Members</span>
                  <div style={{ flex: "1 1 auto", justifyContent: "center", padding: "0 20px" }}>
                    <ProgressBar completed={60} bgColor="#FECB00" style={{ width: "100%" }} />
                  </div>
                  <span style={{ flex: "0 0 auto" }}>$1,500.65</span>
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Close</b>
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#0F1028",
                    borderRadius: "3px",
                    width: "45%" // Adjust the width to fit nicely within the container
                  }}
                  onClick={() => {
                    budgetPop.current.close();
                  }}
                >
                  <b>Export</b>
                </button>
              </div>





            </div>




                </div>*/}
        </Popup>

        <Popup
          ref={budgetEnginePop}
          contentStyle={{/*margin:"auto 10px"*/ }}
        //  closeOnDocumentClick={false} // prevent closing on clicking outside
        //  closeOnEscape={false} // prevent closing with the escape key
          overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} // overlay settings
        >

          <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>

              <p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>Select an engine for your budget:</p>


              <div style={{ padding: "15px 0px" }}>
                <Select

                  options={[{ value: "us", label: "US" }, { value: "mx", label: "MX" }]}
                  value={what}
                  onChange={(v) => {
                    // setselectedtype(v);
                    //alert(v);
                    setwhat(v);

                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#C77CFB',
                      primary: '#0F1028',
                    },
                  })}

                  styles={{
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      background: 'grey',
                    }),
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "3px",
                    }),
                  }}

                />
              </div>


              <p style={{ padding: "5px 0px", marginTop: "5px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {

                    resetCreationBudgetFN();
                    makeBudgetFN(match.params.id, what.value);

                    setTimeout(() => {
                      history.push({
                        pathname: '/budgets'
                      })
                    }, 800);


                  }}
                >
                  <b>Generate Budget</b>
                </button></p>





            </div>




          </div>
        </Popup>



        <Popup
          ref={blockPop}
          contentStyle={{/*margin:"auto 10px"*/ }}
          closeOnDocumentClick={false} // prevent closing on clicking outside
          closeOnEscape={false} // prevent closing with the escape key
          overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} // overlay settings
        >

          <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>

              <p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>You need an active subscription to use Filmica.</p>




              <p style={{ padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  onClick={() => {
                    //scenePop.current.close();
                    //alert("Feature pending development")
                    //setmakingimage(true);
                    //makeImageFN(sceneprompt, match.params.id, scene_id);

                    history.push({
                      pathname: '/billing'
                    })
                  }}
                >
                  <b>Subscribe Now</b>
                </button></p>





            </div>




          </div>
        </Popup>



        <Popup
          ref={breakdownPop}
          contentStyle={{/*margin:"auto 10px"*/ }}
          //closeOnDocumentClick={false} // prevent closing on clicking outside
          //closeOnEscape={false} // prevent closing with the escape key
          overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }} // overlay settings
        >

          <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
            <div style={{ width: "500px" }}>

              <p style={{ color: "#333", fontWeight: "600", textAlign: "left", fontSize: "1.1em" }}>Generate Breakdown Sheet</p>

              <div style={{ height: "15px" }}></div>

              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"PRODUCTION NO"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setproductionno(v.target.value);
                    }}
                    value={productionno}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>



              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"PRODUCTION TITLE"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setproductiontitle(v.target.value);
                    }}
                    value={productiontitle}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>


              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"BREAKDOWN PAGE NO"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setbreakdownpageno(v.target.value);
                    }}
                    value={breakdownpageno}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>

              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"SCENE NO"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setsceneno(v.target.value);
                    }}
                    value={sceneno}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>


              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"SCENE NAME"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setscenename(v.target.value);
                    }}
                    value={scenename}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>


              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"SCRIPT PAGE NO"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setscriptpageno(v.target.value);
                    }}
                    value={scriptpageno}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>

              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"DESCRIPTION"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setdescription(v.target.value);
                    }}
                    value={description}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>


              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"PAGE COUNT"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setpagecount(v.target.value);
                    }}
                    value={pagecount}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>


              <div className="field">
                <p className="control">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"LOCATION NAME"}
                    id="author"
                    name="author"
                    onChange={(v) => {
                      setlocationname(v.target.value);
                    }}
                    value={locationname}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />

                </p>
              </div>

              <p style={{ padding: "5px 0px", marginTop: "20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                    width: "100%"
                  }}
                  /*disabled={ismakingsheet}*/
                  onClick={() => {
                    setismakingsheet(true);
                    //scenePop.current.close();
                    //alert("Feature pending development")
                    //setmakingimage(true);
                    //makeImageFN(sceneprompt, match.params.id, scene_id);
                    // alert(selectedtype.key);
                    return makeSheetFN(selectedtype.key, productionno, productiontitle, breakdownpageno, sceneno, scenename, scriptpageno, description, pagecount, locationname, match.params.id);

                  }}
                  disabled={balance && balance.ai_credits < 1 || !balance.isPaying || ismakingsheet}

                >
                  {ismakingsheet ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Generate Now</b>}


                </button></p>

              {!makingai && <p style={{ color: "#0F1028", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px" }}>{balance && !balance.isPaying ? "You need an active subscriptions to use this feature." : balance && balance.ai_credits < 1 ? "You don't have enough AI Credits to use this feature. Please top-up your account." : `You have ${balance && balance.ai_credits} AI credits left.`}</p>}





            </div>




          </div>
        </Popup>



      </div >

      {
        pageloaded ? <div className="bottommenu">

          < div >
            <p className="leftpanel_title">{screenplay.title}</p>
            <p style={{ padding: "5px 10px", display: "inline-block" }}>Estimated Runtime: <b>{runtime} minutes</b></p>
            <p style={{ padding: "5px 10px", display: "inline-block" }}>Scene Count: <b>{scenes}</b></p>
            <p style={{ padding: "5px 10px", display: "inline-block" }}>Character Count: <b>{character}</b></p>
            <p style={{ padding: "5px 10px", display: "inline-block", color: "#C77CFB", fontWeight: 600 }}>AI Credits Available: <b>{balance.ai_credits}</b></p>

          </div >
        </div > : null
      }
    </div >
  ) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    screenplay: state.home.screenplay,
    edited: state.home.edited,
    budgets: state.home.budgets,
    images: state.home.images,
    balance: state.home.balance,
    error_budgeting: state.home.error_budgeting,
    error_image: state.home.error_image,
    error_budget_pdf: state.home.error_budget_pdf,
    error_ai: state.home.error_ai,
    pdf_done: state.home.pdf_done,
    ai: state.home.ai,
    breakdowbsheet: state.home.breakdowbsheet



  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScrenPlayFN: (a) =>
      dispatch(getScrenPlay(a)),
    getBudgetFN: (a) =>
      dispatch(getBudget(a)),
    resetCreationFN: () =>
      dispatch(resetCreation()),
    makeBudgetFN: (a, b) =>
      dispatch(makeBudget(a, b)),
    editScreenplayFN: (a, b, c, d) =>
      dispatch(editScreenplay(a, b, c, d)),

    resetCreationBudgetFN: () =>
      dispatch(resetCreationBudget()),

    getFullBudgetFN: (a) =>
      dispatch(getFullBudget(a)),

    getImageFN: (a) =>
      dispatch(getImage(a)),

    makeImageFN: (a, b, c) =>
      dispatch(makeImage(a, b, c)),

    makeAIFN: (a, b, c) =>
      dispatch(makeAI(a, b, c)),

    getBalanceFN: () =>
      dispatch(getBalance()),

    makeSheetFN: (scene_id, productionno, productiontitle, breakdownpageno, sceneno, scenename, scriptpageno, description, pagecount, locationname, screen) =>
      dispatch(makeSheet(scene_id, productionno, productiontitle, breakdownpageno, sceneno, scenename, scriptpageno, description, pagecount, locationname, screen)),




  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(ScreenPlay);
