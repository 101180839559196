import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { recover } from "../store/actions/homeActions";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo_3446.svg";



function Recovery(props) {
  const { recoverFN, errore, recovered } = props;
  const [username, setusername] = useState(null);


  const handleSubmit = (e) => {
    e.preventDefault();
    recoverFN(
      username,
    );
  };


  return (
    <div className="container" style={{ marginTop: "60px" }}>
      <div className="columns is-centered" style={{ margin: 13, background: "#0F1028", borderRadius: "3px" }}>
        <div
          className="column is-half"
          style={{
            color: "white",
            padding: "10px !important",
          }}
        >
          <form onSubmit={handleSubmit} style={{}}>
            <div style={{ height: "10px" }}></div>

            <p className="has-text-centered"><Link to="/"><img src={logo} className="logonav2" alt="Logo" /></Link></p>


            <h1 className="has-text-centered">Send me a new password:</h1>
            <div style={{ height: "30px" }}></div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="text"
                  placeholder={"Your e-email address"}
                  id="username"
                  name="username"
                  onChange={(v) => {
                    setusername(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>

            <div className="field">
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#fff",
                  background: "#8F8EFF",
                  borderRadius: "3px",
                  width: "100%"
                }}
              >
                Send me a new password
              </button>
            </div>

            {errore ? <p style={{ color: "red", marginTop: "20px" }}>An account with this email does not exist</p> : null}
            {recovered ? <p style={{ color: "#fff", marginTop: "20px" }}>We have sent you a new password by email! Make sure you check your spam folder.</p> : null}




          </form>
          <div style={{ height: "20px" }}></div>

          <p>Don't have an account yet? Click <Link to="/register" style={{ color: "#8F8EFF", fontWeight: 600 }}>here</Link> to create a new one.</p>




        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errore: state.home.errore,
    recovered: state.home.recovered,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recoverFN: (username) =>
      dispatch(recover(username)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Recovery
);
