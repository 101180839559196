import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getSettings, saveSettings, getBalance, cancelStripe } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';



function Settings(props) {

  const { token, settings, getSettingsFN, saveSettingsFN, balance, getBalanceFN, cancelled, cancelStripeFN } = props;

  const [company, setcompany] = useState("");
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [country, setcountry] = useState("");
  const [canva, setcanva] = useState("");


  useEffect(() => {

    if (cancelled) {
      getBalanceFN();

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelled]);



  useEffect(() => {

    getBalanceFN();
    getSettingsFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {


    setcompany(settings.company);
    setname(settings.full_name);
    setaddress(settings.address);
    setcountry(settings.country);
    setcanva(settings.canva_api);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);




  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >
          <h1>Settings</h1>
          <p style={{ color: "#909090" }}>Enter your account details below</p>

          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Company name"}
                id="c"
                name="c"
                onChange={(v) => {
                  setcompany(v.target.value);
                }}
                value={company}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-building"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Your full name"}
                id="c"
                name="c"
                value={name}
                onChange={(v) => {
                  setname(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Address"}
                id="c"
                name="c"
                value={address}
                onChange={(v) => {
                  setaddress(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Country"}
                id="c"
                name="c"
                value={country}
                onChange={(v) => {
                  setcountry(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-globe"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "10px" }}></div>

          <div style={{ marginBottom: "20px" }}>{balance && balance.isPaying && balance.willcancel !== 1 ? <p>Click <a onClick={() => {
            cancelStripeFN();
          }}>here</a> to cancel your Filmica subscription.</p> : balance && balance.isPaying && balance.willcancel === 1 ? <p>Your subscription will cancel at the end of the current billing cycle.</p> : null}</div>
          <button
            style={{
              padding: "10px 15px",
              fontSize: "1.1rem",
              fontWeight: "600",
              border: 0,
              color: "#fff",
              background: "#8F8EFF",
              borderRadius: "3px",
            }}

            onClick={() => {
              saveSettingsFN(company, name, address, country, canva);

            }}
          >
            Save
          </button>
          <div style={{ height: "10px" }}></div>


        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    settings: state.home.settings,
    balance: state.home.balance,
    cancelled: state.home.cancelled,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsFN: () =>
      dispatch(getSettings()),

    getBalanceFN: () =>
      dispatch(getBalance()),

    cancelStripeFN: () =>
      dispatch(cancelStripe()),


    saveSettingsFN: (a, b, c, d, e) =>
      dispatch(saveSettings(a, b, c, d, e)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Settings);
