import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getProductionData, editProductionData, resetCreation } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import LOADER from "../assets/images/loader.svg";

import Spreadsheet from "react-spreadsheet";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


function AdminData(props) {

  const { token, resetCreationFN, match, production_data, production_data_edited, getProductionDataFN, editProductionDataFN } = props;

  const [arra, setarra] = useState([]);
  const [arra_mx, setarra_mx] = useState([]);

  const [issaving, setissaving] = useState(false);
  const [what, setwhat] = useState("us");


  const history = useHistory();


  const columnLabels = ["ACCOUNT NO", "DESCRIPTION", "AMOUNT", "UNITS", "RATE", "SUBTOTAL"];


  const handleChange = (newData) => {
    //console.log(JSON.stringify(newData));
    setarra(newData);
  };

  const handleChange_mx = (newData) => {
    //console.log(JSON.stringify(newData));
    setarra_mx(newData);
  };



  useEffect(() => {

    getProductionDataFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (production_data && production_data.length > 0) {
      //console.log(JSON.stringify(transformArray3(JSON.parse(budgets_single[0]['budget']))));
      //setarra(transformArray3(JSON.parse(budgets_single[0]['budget'])));
      setarra(JSON.parse(production_data[0]['budget_usa']));
      setarra_mx(JSON.parse(production_data[0]['budget_mx']));


    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [production_data]);



  useEffect(() => {

    if (production_data_edited) {

      setissaving(false);
      resetCreationFN();

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [production_data_edited]);




  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >
          <div style={{ height: "15px" }}></div>
          <h1 style={{ padding: "0 20px" }}>Edit Production Cost Data Prices</h1>
          <p style={{ padding: "0 20px", fontSize: "0.8em" }}>Only edit UNITS and/or RATE</p>
          <div style={{ height: "15px" }}></div>

          <Tabs onSelect={(index) => setwhat(index === 0 ? "us" : "mx")}>
            <TabList style={{ padding: "0 20px" }}>
              <Tab>US DATA</Tab>
              <Tab>MX DATA</Tab>
            </TabList>

            <TabPanel>

              <div style={{ padding: "5px 20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    //budgetPop.current.open()
                    setissaving(true);
                    editProductionDataFN(match.params.id, arra, "us");
                  }}
                  disabled={issaving}
                >
                  {issaving ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save US Data</b>}
                </button></div>


              <div style={{ height: "5px" }}></div>

              <Spreadsheet
                data={arra}
                columnLabels={columnLabels}
                className="sheet"
                hideRowIndicators={true}
                onChange={handleChange}
              />

            </TabPanel>

            <TabPanel>
              <div style={{ padding: "5px 20px" }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#8F8EFF",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    //budgetPop.current.open()
                    setissaving(true);
                    editProductionDataFN(match.params.id, arra_mx, "mx");
                  }}
                  disabled={issaving}
                >
                  {issaving ? <img alt="loading.." src={LOADER} style={{ height: "15px" }} /> : <b>Save MX Data</b>}
                </button></div>


              <div style={{ height: "5px" }}></div>

              <Spreadsheet
                data={arra_mx}
                columnLabels={columnLabels}
                className="sheet"
                hideRowIndicators={true}
                onChange={handleChange_mx}
              />
            </TabPanel>

          </Tabs>


        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
        <div style={{ height: "65px" }}></div>

      </div>








    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    balance: state.home.balance,
    production_data: state.home.production_data,
    production_data_edited: state.home.production_data_edited




  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    editProductionDataFN: (a, b, c) =>
      dispatch(editProductionData(a, b, c)),

    getProductionDataFN: (a) =>
      dispatch(getProductionData(a)),


    resetCreationFN: () =>
      dispatch(resetCreation()),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminData);
