export default function Terms() {



  return (<div>
    <div className="container">
      <div className="columns" style={{ marginTop: "70px" }}>
        <div className="column">
          <div className="content no-select" style={{ color: "black !important" }}>

            <h3 style={{ color: "#000" }}>Terms of Service for filmica</h3>

            <p style={{ color: "#000" }}>
              Welcome to filmica! By using our AI-powered screenwriting tool, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.
            </p>

            <h3 style={{ color: "#000" }}>1. Acceptance of Terms</h3>

            <p style={{ color: "#000" }}>
              By accessing or using filmica, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with any part of these terms, you must not use filmica.
            </p>

            <h3 style={{ color: "#000" }}>2. Use of the Service</h3>

            <p style={{ color: "#000" }}>
              filmica grants you a limited, non-exclusive, non-transferable, and revocable license to use the service for personal or professional screenwriting creation. You agree not to use filmica for any unlawful or prohibited activities.
            </p>

            <h3 style={{ color: "#000" }}>3. User Accounts</h3>

            <p style={{ color: "#000" }}>
              You must create an account to use certain features of filmica. You agree to provide accurate and complete information when creating your account and to keep this information up-to-date. You are responsible for maintaining the confidentiality of your account credentials.
            </p>

            <h3 style={{ color: "#000" }}>4. Payment and Subscription</h3>

            <p style={{ color: "#000" }}>
              If you purchase a subscription to filmica, you agree to pay the applicable fees. Subscriptions will automatically renew unless canceled prior to the renewal date. All fees are non-refundable except as required by law.
            </p>

            <h3 style={{ color: "#000" }}>5. Intellectual Property</h3>

            <p style={{ color: "#000" }}>
              All content generated by filmica is the intellectual property of the user. However, filmica retains all rights, titles, and interests in the underlying AI technology and software.
            </p>

            <h3 style={{ color: "#000" }}>6. Termination</h3>

            <p style={{ color: "#000" }}>
              filmica reserves the right to terminate or suspend your account and access to the service at our sole discretion, without notice, for conduct that we believe violates these terms or is harmful to other users.
            </p>

            <h3 style={{ color: "#000" }}>7. Limitation of Liability</h3>

            <p style={{ color: "#000" }}>
              filmica is provided "as is" and "as available." We do not warrant that the service will be uninterrupted or error-free. In no event shall filmica be liable for any damages arising from the use or inability to use the service.
            </p>

            <h3 style={{ color: "#000" }}>8. Governing Law</h3>

            <p style={{ color: "#000" }}>
              These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which filmica operates, without regard to its conflict of law principles.
            </p>

            <h3 style={{ color: "#000" }}>9. Changes to Terms</h3>

            <p style={{ color: "#000" }}>
              We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new terms on this page and updating the effective date.
            </p>

            <h3 style={{ color: "#000" }}>10. Contact Us</h3>

            <p style={{ color: "#000" }}>
              If you have any questions about these Terms of Service, please contact us at <a href="mailto:support@filmica.com" style={{ color: "#000" }}>support@filmica.com</a>.
            </p>

            <p style={{ color: "#000" }}>
              **Effective Date:** August 06, 2024
            </p>



          </div>
        </div>
      </div>
      <div style={{ height: "10px" }}></div>

      <p style={{ color: "black" }}>&copy; 2024 filmica</p>

    </div>


  </div>);
}
