import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getTXs, getStripe, resetCreation, getBalance, getReferrals } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Billing(props) {

  const { token, txs, getTXsFN, loading, price_r, match, redirect_url, getStripeFN, resetCreationFN, getBalanceFN, balance, getReferralsFN, referrals } = props;

  const canvaRef = useRef();
  const canvaRef2 = useRef();
  const subRef = useRef();
  //const [amount, setamount] = useState(1);
  const [price, setprice] = useState(1);
  const [isloading, setisloading] = useState(false);

  const [selectedValue, setSelectedValue] = useState(100);
  const [selectedValue2, setSelectedValue2] = useState(1);

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(parseInt(event.target.value));
  };

  const handleChange2 = (event) => {
    console.log(event.target.value);
    setSelectedValue2(parseInt(event.target.value));
  };

  //const [totalRows, setTotalRows] = useState(0);
  //const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    //alert(match.params.id);
    if (match.params.id) {
      canvaRef2.current.open();

    }
    getReferralsFN();
    getBalanceFN();
    getTXsFN();
    // getPriceFN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    setprice(price_r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price_r]);


  useEffect(() => {

    if (redirect_url) {
      resetCreationFN();
      window.location.href = redirect_url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect_url]);


  /*
    const handlePageChange = page => {
      getTXsFN(page, perPage);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      getTXsFN(newPerPage);
      getTXsFN(page, newPerPage);
    };
  
  */

  const columns_referral = [

    {
      name: 'User Referred',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.email}</p>),
      sortable: true,
      width: '40%',
    },
    {
      name: 'Registered on',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Converted on',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.dt2 ? moment(row.dt2).format('MMMM D, YYYY [at] h:mma') : "Pending"}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Award',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.referral_type || "Pending"}</p>),
      sortable: true,
      width: '20%',

    },

  ];

  const columns = [

    {
      name: 'Credits',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.credits}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Amount',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{"$" + row.amount}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Method',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.method}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => parseInt(row.status) === 0 ? (<span style={{ color: "#000", fontSize: "1.2em" }}>Failed</span>) : (<span style={{ color: "#79CA27", fontSize: "1.2em" }}>Paid</span>),
      sortable: true,
    },/*
    {
      name: 'Invoice',
      selector: row => (<p><button style={{
        padding: "5px 10px",
        fontSize: "1.2em",
        fontWeight: "400",
        border: 0,
        color: "#000",
        background: "#FECB00",
        borderRadius: "3px",
      }} onClick={() => {
        window.open(row.receipt);

      }}>Get Invoice</button></p>),
      sortable: true,
    }*/
  ];


  return token ? (
    <div className="container" style={{ marginTop: "90px" }}>




      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >

          <div className="flex-container">


            <button className="left-button" style={{
              padding: "10px 25px",
              fontSize: "1.2em",
              fontWeight: "600",
              border: 0,
              color: "#fff",
              background: "#C77CFB",
              borderRadius: "3px",
            }} onClick={() => {

              if (!balance.isPaying) {
                if (subRef.current) {
                  subRef.current.open();

                }
              }
              else {
                if (canvaRef.current) {
                  canvaRef.current.open();

                }
              }
            }}>
              {balance.isPaying ? "Buy AI credits" : "Subscribe Now"}
            </button>

            {!balance.isPaying ? <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px", display: "contents" }}>You need an active subscription to use Filmica.</p> : null}

          </div>




          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {txs === undefined || !txs || txs.length < 1 ? <p style={{ padding: "20px 20px 20px 20px" }}>You haven't made any payment yet.</p> : <DataTable
            title="My Transactions"
            columns={columns}
            data={txs}
            progressPending={loading}
            pagination={true}

            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={txs.length} // Total number of rows in your data
            paginationDefaultPage={1}


          //paginationTotalRows={totalRows}
          //onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
          />}

        </div>
      </div>



      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >


          <div className="flex-container">




            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "0.8em", marginTop: "10px", display: "contents" }}>Your referral URL is: <span style={{ color: "#C77CFB", marginTop: "10px", display: "contents" }}> https://beta.filmica.ai/register/{balance.referral_code}</span></p>

          </div>






          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {referrals === undefined || !referrals || referrals.length < 1 ? <p style={{ padding: "20px 20px 20px 20px" }}>You haven't referred any user yet.</p> : <DataTable
            title="My Referrals"
            columns={columns_referral}
            data={referrals}
            progressPending={loading}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={referrals.length} // Total number of rows in your data
            paginationDefaultPage={1}

          />}

        </div>
      </div>



      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2024 filmica</p>
      </div>



      <Popup
        ref={canvaRef2}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
          <div style={{ width: "300px", background: "#E5E6EC!important" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>
            {match.params.id === "success" ? "Payment Successful" : "Payment Failed"}
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              {match.params.id === "success"
                ? "Please wait a few seconds while we process your payment and credit your account."
                : "There was an issue with your payment. Please try again."}
            </p>
            <div style={{ height: "15px" }}></div>


          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {



            canvaRef2.current.close();

          }}><b>Close</b></button>



          </div>


        </div>
      </Popup>


      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
          <div style={{ width: "600px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.2em" }}>Buy AI Credits
          </p>
            <div style={{ height: "15px" }}></div>
            <div className="billingt">
              <div className="billingt2">
                <input type="radio" id="control_01" name="select"
                  value="50"
                  checked={selectedValue === 50}
                  onChange={handleChange}

                />
                <label htmlFor="control_01" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>50 AI Credits</h2>
                  <p>${(balance.price_per_credit_1 * 50).toFixed(2)}<br></br>${balance.price_per_credit_1.toFixed(2)} per credit</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_02" name="select" value="100"
                  checked={selectedValue === 100}
                  onChange={handleChange} />
                <label htmlFor="control_02" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>100 Credits</h2>
                  <p>${(balance.price_per_credit_2 * 100).toFixed(2)}<br></br>${balance.price_per_credit_2.toFixed(2)} per credit</p>

                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_03" name="select" value="200"
                  checked={selectedValue === 200}
                  onChange={handleChange} />
                <label htmlFor="control_03" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>200 Credits</h2>
                  <p>${(balance.price_per_credit_3 * 200).toFixed(2)}<br></br>${balance.price_per_credit_3.toFixed(2)} per credit</p>
                </label>
              </div>
            </div>



          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}>





            <button disabled={isloading} className="" style={{
              border: 0,
              fontSize: "1.5em",
              padding: "10px 35px", lineHeight: "30px", background: "hsla(150, 75%, 45%, 1)", color: "white", borderRadius: "3px"
            }} onClick={() => {



              //canvaRef.current.close();
              //go2checkout();
              setisloading(true);
              getStripeFN(price, selectedValue, null);

            }}><b>Go to Checkout</b></button>


            <div style={{ height: "15px" }}></div>

            <p style={{ textDecoration: "underline", color: "#000" }} onClick={() => {

              canvaRef.current.close();

            }}>No, thanks!</p>
          </div>


        </div>
      </Popup >



      <Popup
        ref={subRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 40px", background: "#E5E6EC", borderRadius: "10px" }}>
          <div style={{ width: "600px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.2em" }}>Start using Filmica
          </p>
            <div style={{ height: "15px" }}></div>
            <div className="billingt">
              <div className="billingt2">
                <input type="radio" id="control_01" name="select"
                  value="1"
                  checked={selectedValue2 === 1}
                  onChange={handleChange2}

                />
                <label htmlFor="control_01" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>Monthly Subscription</h2>
                  <p>$12.00<br></br>Unlimited Access<br />100 AI Credits every month</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_02" name="select" value="2"
                  checked={selectedValue2 === 2}
                  onChange={handleChange2} />
                <label htmlFor="control_02" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>Yearly Subscription</h2>
                  <p>$100.00<br></br>Unlimited Access<br />1,200 AI Credits every year</p>
                </label>
              </div>

            </div>



          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}>





            <button disabled={isloading} className="" style={{
              border: 0,
              fontSize: "1.5em",
              padding: "10px 35px", lineHeight: "30px", background: "hsla(150, 75%, 45%, 1)", color: "white", borderRadius: "3px"
            }} onClick={() => {



              //canvaRef.current.close();
              //go2checkout();
              setisloading(true);
              getStripeFN(price, selectedValue2, "sub");

            }}><b>Subscribe Now</b></button>


            <div style={{ height: "15px" }}></div>

            <p style={{ textDecoration: "underline", color: "#000" }} onClick={() => {

              subRef.current.close();

            }}>No, thanks!</p>
          </div>


        </div>
      </Popup >



    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    txs: state.home.txs,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    price_r: state.home.price,
    redirect_url: state.home.redirect_url,
    balance: state.home.balance,
    referrals: state.home.referrals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTXsFN: (a, b) =>
      dispatch(getTXs(a, b)),
    getStripeFN: (a, b, c) =>
      dispatch(getStripe(a, b, c)),
    resetCreationFN: () =>
      dispatch(resetCreation()),
    getBalanceFN: () =>
      dispatch(getBalance()),
    getReferralsFN: () =>
      dispatch(getReferrals())
  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Billing);
